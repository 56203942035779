import WasmController from "react-lib/frameworks/WasmController";

import DoctorDetail from "issara-sdk/apis/DoctorDetail_core";
import EncounterDetail from "issara-sdk/apis/EncounterDetail_core";
import TriageFromEncounter from "issara-sdk/apis/TriageFromEncounter_core";

import SupplyOrderDetail from "issara-sdk/apis/SupplyOrderDetail_apps_MSD";
import SupplyOrderList from "issara-sdk/apis/SupplyOrderList_apps_MSD";

import DiagnosisMedicalRecordDetail from "issara-sdk/apis/DiagnosisMedicalRecordDetail_apps_DPO";
import MedicalRecordGalleryList from "issara-sdk/apis/MedicalRecordGalleryList_apps_DPO";
import PatientEMRList from "issara-sdk/apis/PatientEMRList_apps_DPO";
import ProblemListList from "issara-sdk/apis/ProblemListList_apps_DPO";

import AdverseReactionList from "issara-sdk/apis/AdverseReactionList_apps_ADR";

import FormDataLatest from "issara-sdk/apis/FormDataLatest_apps_PTM";

import TreatmentOrderList from "issara-sdk/apis/TreatmentOrderList_apps_TRT";

import MedicationReconciliationNewDetail from "issara-sdk/apis/MedicationReconciliationNewDetail_apps_TPD";
import MedicationReconciliationNewList from "issara-sdk/apis/MedicationReconciliationNewList_apps_TPD";

import AdmitOrderRoomItemList from "issara-sdk/apis/AdmitOrderRoomItemList_apps_ADM";

import getPdfMake from "react-lib/appcon/common/pdfMake";

import FormDischargeSummary from "react-lib/apps/HISV3/PHV/FormDischargeSummary";
import FormEMRSummary, { FormEMRSummaryProps } from "react-lib/apps/HISV3/PHV/FormEMRSummary";

import {
  alcoholOptions,
  alcoholSpecificOptions,
  pregnancyPeriodOptions,
  tobaccoOptions,
  tobaccoSpecificOptions,
} from "react-lib/apps/HISV3/ADM/sequence/PreAssessment";
import {
  EDUCATION,
  EDUCATION_EVALUATION,
  EDUCATION_WAY,
} from "react-lib/apps/HISV3/PTM/sequence/Assessment";
import { SORT_ADR_ORDER } from "react-lib/apps/HISV3/TPD/sequence/Allergy";

// Types and Interfaces
export type State = Partial<{
  django: any;
}>;

export const StateInitial: State = {};

export type Event =
  | { message: "GetMasterData"; params: Record<string, unknown> }
  | { message: "PrintDischargeSummaryForm"; params: any }
  | { message: "PrintEMRSummaryForm"; params: any };

export type Data = {
  device?: number;
  division?: number;
  masterData: {
    [key: string]: Record<string, any>[] | undefined;
    diagnosisType: any[];
    patientCondition?: any[];
    patientDischarge?: any[];
    patientEducation?: any[];
    prenameEn: any[];
    prenameTh: any[];
    triageLevel?: any[];
  };
};

type VitalSignsKey = (typeof VITAL_SIGNS_KEY)[number][0];

type Handler = (controller: WasmController<State, Event, Data>, params?: any) => any;

// Constants
export const DataInitial = {};

const PREGNANCY_STATUS: Record<number, string> = {
  1: "ไม่ทราบ",
  2: "ไม่ตั้งครรภ์",
  3: "กำลังตั้งครรภ์",
};

const VITAL_SIGNS_KEY = [
  ["bmi", "BMI"],
  ["bp", "BP"],
  ["height", "Height"],
  ["o2sat", "O2Sat"],
  ["pr", "PR"],
  ["rr", "RR"],
  ["temp", "BT"],
  ["weight", "Weight"],
  ["ps", "PS"],
] as const;

export const SORT_DRUG_ORDER: Record<string, number> = {
  HOME_OPD: 4,
  ONE_DAY: 3,
  ONE_DOSE: 2,
  STAT: 1,
};

export const SORT_SUPPLY_ORDER: Record<string, number> = {
  COST_CENTER: 3,
  HOME_OPD: 4,
  REFILL_OPD: 2,
  STAT: 1,
};

// Functions
export const PrintEMRSummaryForm: Handler = async (controller, params) => {
  const { emr, encounterId, vitalSign } = params.emrSummaryData;
  const { patientData } = params.patientPanelData;

  await controller.handleEvent({
    message: "GetMasterData",
    params: {
      masters: [
        ["prenameTh", {}],
        ["prenameEn", {}],
        ["triageLevel", {}],
        ["patientEducation", {}],
        ["patientCondition", {}],
        ["patientDischarge", {}],
      ],
    },
  });

  const { masterData } = controller.data;

  const { enDetail, headerDetail } = await GetFormHeaderDetail(controller, {
    encounterId,
    patientData,
  });

  const {
    adr,
    assessmentDetail,
    diagnosisDetail,
    gallery,
    medReconcileDetail,
    medRecordDetail,
    painDetail,
    pregnancyDetail,
    problem,
    reassessmentDetail,
    supply,
    treatment,
    triageDetail,
  } = await fetchPatientEncounterData({
    apiToken: controller.apiToken,
    encounterId,
    enDetail,
    medicalRecordId: params.medicalRecordId,
    patientId: patientData.id,
  });

  const triage =
    masterData.triageLevel?.find((item) => item.id === triageDetail.triage_level) || {};

  const pregnancyPeriod = pregnancyPeriodOptions.find(
    (option) => option.value === pregnancyDetail?.data?.pregnancy_period
  );

  const problemItems: any[] = problem?.items || [];

  const underlyingDisease = problemItems
    .filter((item) => item.is_active)
    .map((item) => item.message as string)
    .join(", ");

  const allergyText = formatAllergy(adr?.items || []);

  const medRecItems: Record<string, any>[] = medReconcileDetail?.items || [];

  const currentMedication = medRecItems.map((item) => item.name as string).join(", ");

  const vitalSingItems: any[] = vitalSign?.items || [];

  const physicianNote = formatPhysicianNote(emr[1]);

  const { doctorNoteItems, drugItems, imagingItems, labItems, supplyItems } = getDoctorOrder(
    enDetail,
    supply
  );

  const dischargeStatus =
    masterData.patientDischarge?.find((item) => item.id === enDetail.discharge_status) || {};

  const preDischargeCondition =
    masterData.patientCondition?.find((item) => item.id === enDetail.predischarge_condition) || {};

  const docDef = await FormEMRSummary({
    allergyText,
    assessment: formatPatientAssessment(assessmentDetail),
    currentMedication,
    detail: headerDetail,
    diagnosis: diagnosisDetail,
    discharge: {
      note: enDetail.discharge_note,
      preDischargeConditionName: preDischargeCondition.name || "",
      statusName: dischargeStatus.name || "",
    },
    doctorNoteItems,
    drugItems,
    galleryItems: gallery?.items || [],
    imagingItems,
    labItems,
    medicalRecord: medRecordDetail,
    painScore: painDetail?.data?.pain_score || "",
    physicianNote,
    pregnancy: {
      periodName: pregnancyPeriod?.text,
      statusName: PREGNANCY_STATUS[pregnancyDetail?.data?.pregnancy_status || ""],
    },
    reassessment: formatReassessmentEducation(reassessmentDetail, masterData),
    supplyOrders: supplyItems,
    treatmentOrders: treatment?.items || [],
    triage: {
      arrive_status: triageDetail.arrive_status,
      triageLevelName: triage.value,
    },
    underlyingDisease,
    vitalsign: formatVitalSigns(vitalSingItems.slice(-1)[0]?.vital_signs || []),
  });

  const pdfMake = await getPdfMake(true);
  const pdf = pdfMake.createPdf(docDef);

  pdf.open();
};

export const PrintDischargeSummaryForm: Handler = async (controller, params) => {
  const state = controller.getState();
  const { admissionForm, diagnosis, dischargeSummary, procedure } = params.dischargeSummaryData;

  await controller.handleEvent({
    message: "GetMasterData",
    params: {
      masters: [
        ["diagnosisType", {}],
        ["prenameTh", {}],
        ["prenameEn", {}],
      ],
    },
  });

  const [admit, { enDetail, headerDetail }] = await Promise.all([
    GetAdmitOrderRoomItem(controller, dischargeSummary.encounter),
    GetFormHeaderDetail(controller, {
      encounterId: dischargeSummary.encounter.id,
      patientData: params.patientPanelData.patientData,
    }),
  ]);

  const [[attendingPhysician], [resident]] = await Promise.all([
    DoctorDetail.retrieve({
      apiToken: controller.apiToken,
      pk: dischargeSummary.detail.attending_physician?.id || enDetail.doctor,
    }),
    DoctorDetail.retrieve({
      apiToken: controller.apiToken,
      pk: dischargeSummary.detail.resident?.id,
    }),
  ]);

  const { masterData } = controller.data;
  const diagnosisSecondary: Record<string, any>[] = diagnosis.secondary || [];

  const attendingPhysicianName = formatDoctorName(attendingPhysician, masterData).thaiFormat;
  const residentName = formatDoctorName(resident || {}, masterData).thaiFormat;

  diagnosis.secondary = diagnosisSecondary.map((item) => ({
    ...item,
    label: masterData.diagnosisType.find((acc) => acc.value === item.type)?.label,
  }));

  const docDef = await FormDischargeSummary({
    attendingPhysicianName,
    detail: headerDetail,
    diagnosis,
    dischargeDate: enDetail.ended,
    dischargeSummary,
    procedure,
    provisionalDiagnosis: admissionForm.provisional_diagnosis,
    residentName,
    startWardDate: admit.start_warded,
    userFullName: state.django.user?.full_name || "",
  });
  const pdfMake = await getPdfMake(true);
  const pdf = pdfMake.createPdf(docDef);

  pdf.open();
};

const GetFormHeaderDetail: Handler = async (controller, params) => {
  const { encounterId, patientData } = params;

  // ดึงข้อมูล Encounter เพื่อใช้ในการเรียก API อื่นๆ
  const [enDetail] = await EncounterDetail.retrieve({
    apiToken: controller.apiToken,
    pk: encounterId,
  });

  const [doctorDetail] = await DoctorDetail.retrieve({
    apiToken: controller.apiToken,
    pk: enDetail.doctor,
  });

  const { masterData } = controller.data;

  const division = masterData.division?.find((item) => item.id === enDetail.division) || {};

  const doctor = formatDoctorName(doctorDetail, masterData);

  const presentAddress = patientData.present_address || {};

  return {
    doctorDetail,
    enDetail,
    headerDetail: {
      address: formatThaiAddress(presentAddress),
      citizen_no: patientData.citizen_no,
      citizen_passport: patientData.citizen_passport,
      divisionName: division.name_en || division.name,
      doctorName: doctor.standardFormat,
      hn: enDetail.hn,
      number: enDetail?.full_number || enDetail.number,
      patient_age: enDetail.patient_age,
      patient_birthdate: enDetail.patient_birthdate,
      patient_gender_name: enDetail.patient_gender_name,
      patientName: patientData.full_name_en || patientData.full_name_th,
      profileImage: patientData.profile_image?.image,
      started: enDetail.started,
      telMobile: presentAddress?.tel_mobile || "",
    },
  };
};

/** ====================================================  */
/**                         APIS                          */
/** ====================================================  */
const GetMedicationReconciliation = async (params: { apiToken: string; encounterId: number }) => {
  const medRecRes: Promise<[any, any]> = MedicationReconciliationNewList.list({
    apiToken: params.apiToken,
    params: { encounter: params.encounterId },
  });

  return medRecRes.then(async ([res]) => {
    const items: Record<string, any>[] = res?.items || [];

    const data = items.find((item) => item.med_reconciliation_type === "OPD");

    return data?.id
      ? (MedicationReconciliationNewDetail.retrieve({
          apiToken: params.apiToken,
          pk: data.id,
        }) as Promise<[any, any]>)
      : ([] as any[]);
  });
};

const GetSupplyOrderList = async (params: { apiToken: string; encounterId: number }) => {
  const supplyListRes: Promise<[any, any]> = SupplyOrderList.list({
    apiToken: params.apiToken,
    params: { encounter: params.encounterId },
  });

  return supplyListRes.then(async ([res]) => {
    const items: Record<string, any>[] = res?.items || [];

    const promiseArr = items.map(
      async (item) =>
        SupplyOrderDetail.retrieve({
          apiToken: params.apiToken,
          pk: item.id,
        }) as Promise<[any, any]>
    );

    return [await Promise.all(promiseArr)];
  });
};

const GetAdmitOrderRoomItem: Handler = async (controller, params) => {
  const [admitOrderItem] = await AdmitOrderRoomItemList.list({
    apiToken: controller.apiToken,
    params: { barcode_or_an_or_hn: params.number, for_queue: false },
    extra: { division: controller.data.division },
  });

  const admitOrderItems: Record<string, any>[] = admitOrderItem?.items || [];

  return admitOrderItems.find((item) => item.encounter.id === params.id) || {};
};

const fetchPatientEncounterData = async (params: {
  apiToken: string;
  encounterId: number;
  enDetail: Record<string, any>;
  medicalRecordId: string;
  patientId: string;
}) => {
  const { apiToken, encounterId, enDetail, medicalRecordId, patientId } = params;

  const apiCalls = [
    TriageFromEncounter.retrieve({ apiToken, pk: encounterId }),
    FormDataLatest.retrieve({
      apiToken,
      params: { encounter: encounterId, form_code: "CardPregnancyAssessment", form_version: "1.0" },
    }),
    FormDataLatest.retrieve({
      apiToken,
      params: { encounter: encounterId, form_code: "CardPatientAssessment", form_version: "0.2" },
    }),
    FormDataLatest.retrieve({
      apiToken,
      params: { encounter: encounterId, form_code: "CardPainAssessment", form_version: "0.1" },
    }),
    FormDataLatest.retrieve({
      apiToken,
      params: { encounter: encounterId, form_code: "CardReassessment", form_version: "0.1" },
    }),
    DiagnosisMedicalRecordDetail.retrieve({ apiToken, pk: medicalRecordId }),
    ProblemListList.list({ apiToken, params: { patient: patientId } }),
    AdverseReactionList.list({
      apiToken,
      params: { exclude_unused: true, patient: patientId, severe_first: true },
    }),
    GetMedicationReconciliation({ apiToken, encounterId }),
    PatientEMRList.get({
      apiToken,
      emr: medicalRecordId,
      params: { auto_checkin: false },
      extra: { division: enDetail.division },
    }),
    TreatmentOrderList.list({
      apiToken,
      params: { encounter_id: encounterId },
    }),
    GetSupplyOrderList({ apiToken, encounterId }),
    MedicalRecordGalleryList.list({ apiToken, emr: medicalRecordId }),
  ];

  const apiCallResults = await Promise.all(apiCalls);

  const resultKeys = [
    "triageDetail",
    "pregnancyDetail",
    "assessmentDetail",
    "painDetail",
    "reassessmentDetail",
    "diagnosisDetail",
    "problem",
    "adr",
    "medReconcileDetail",
    "medRecordDetail",
    "treatment",
    "supply",
    "gallery",
  ] as const;

  const resultEntries = resultKeys.map(
    (key, index) => [key, apiCallResults[index][0]] as [(typeof resultKeys)[number], any]
  );
  const results = Object.fromEntries(resultEntries) as Record<(typeof resultKeys)[number], any>;

  return {
    enDetail,
    ...results,
  };
};

/** ====================================================  */
/**                        Format                         */
/** ====================================================  */
const formatDoctorName = (
  doctorDetail: Record<string, any>,
  masterData: { prenameEn?: any[]; prenameTh?: any[] }
) => {
  const isEnglish = masterData.prenameEn?.some((item) => item.id === doctorDetail.pre_name_en);
  const prename = isEnglish
    ? masterData.prenameEn?.find((item) => item.id === doctorDetail.pre_name_en)
    : masterData.prenameTh?.find((item) => item.id === doctorDetail.pre_name);

  const name = [
    isEnglish ? doctorDetail.first_name_en : doctorDetail.first_name,
    isEnglish ? doctorDetail.middle_name_en : doctorDetail.middle_name,
    isEnglish ? doctorDetail.last_name_en : doctorDetail.last_name,
  ]
    .filter(Boolean)
    .join(" ");

  let certificateNo: string = doctorDetail.certificate_no;

  certificateNo = certificateNo ? `(${certificateNo.replace("ว.", "")})` : "";

  return {
    standardFormat: `${name}, ${prename?.name || ""} ${certificateNo}`,
    thaiFormat: `${prename?.name || ""}${name} ${certificateNo}`,
  };
};

const formatVitalSigns = (data: any[]) => {
  const result: Record<string, string> = {};

  for (const [key, oldKey] of VITAL_SIGNS_KEY) {
    const item = data.find((item) => item.vitalsign_type_code === oldKey);

    if (item) {
      result[key] = item.result;
    }
  }

  // สำหรับ blood pressure
  const systolic = data.find((item) => item.vitalsign_type_code === "SP");
  const diastolic = data.find((item) => item.vitalsign_type_code === "DP");

  if (systolic && diastolic) {
    result.bp = `${systolic.result}/${diastolic.result}`;
  }

  return result as Record<VitalSignsKey, string>;
};

const formatPhysicianNote = (data: Record<string, any>[]) => {
  const questionMap: Record<string, keyof FormEMRSummaryProps["physicianNote"] | undefined> = {
    "Chief Complaint": "chiefComplaints",
    "Family History": "familyHistory",
    "Past Illness": "pastIllness",
    "Personal History": "personalHistory",
    "Physician note (Present illness, Past history and relevant information)": "presentIllness",
  };

  const mappedEntries = data
    .filter((item) => questionMap[item.question])
    .map((item) => [questionMap[item.question], item.answer] as [string, string]);

  return { ...Object.fromEntries(mappedEntries) } as FormEMRSummaryProps["physicianNote"];
};

const formatReassessmentEducation = (
  reassessmentDetail: any,
  masterData: { patientEducation?: any[] }
) => {
  const reassessmentData = reassessmentDetail?.data || {};

  const educationEvaluation = EDUCATION_EVALUATION.find(
    (option) => option.value === reassessmentData.education_evaluation
  );

  const educationWay: string[] = reassessmentData.education_way || [];
  const educationWayName = educationWay
    .map((value) => EDUCATION_WAY.find((option) => option.value === value)?.text)
    .filter(Boolean)
    .join(", ");

  const education: string[] = reassessmentData.education || [];
  const educationName = education
    .map((value) => EDUCATION.find((option) => option.value === value)?.text)
    .filter(Boolean)
    .join(", ");

  const educationMethod: string[] = reassessmentData.education_method || [];
  const educationMethodName = educationMethod
    .map((value) => {
      const patientEducation: any[] = masterData.patientEducation || [];
      const text: string =
        patientEducation.find((option) => option.id === Number(value))?.name || "";

      return text;
    })
    .filter(Boolean)
    .join(", ");

  return reassessmentDetail?.data
    ? {
        barriers: reassessmentData.barriers,
        barriers_detail: reassessmentData.barriers_detail,
        education_evaluation_detail: reassessmentData.education_evaluation_detail,
        educationEvaluationName: educationEvaluation?.text,
        educationMethodName,
        educationName,
        educationWayName,
        follow_up_detail: reassessmentData.follow_up_detail,
        readiness: reassessmentData.readiness,
        readiness_detail: reassessmentData.readiness_detail,
      }
    : null;
};

const formatPatientAssessment = (assessmentDetail: any) => {
  const reassessmentData = assessmentDetail?.data || {};

  const alcohol = alcoholOptions.find((option) => option.value === reassessmentData.alcohol);
  const alcoholSpecific = alcoholSpecificOptions.find(
    (option) => option.value === reassessmentData.alcohol_specific
  );

  const tobacco = tobaccoOptions.find((option) => option.value === reassessmentData.tobacco);
  const tobaccoSpecific = tobaccoSpecificOptions.find(
    (option) => option.value === reassessmentData.tobacco_specific
  );

  return {
    alcoholName: alcohol?.text,
    alcoholSpecificName: alcoholSpecific?.text,
    tobaccoName: tobacco?.text,
    tobaccoSpecificName: tobaccoSpecific?.text,
  };
};

const formatAllergy = (adrItems: Record<string, any>[] | undefined) => {
  const sortedAdr = (adrItems || []).sort(
    (a, b) => SORT_ADR_ORDER[a.type_name_name] - SORT_ADR_ORDER[b.type_name_name]
  );

  return sortedAdr
    .map((acc) => acc.name as string)
    .filter(Boolean)
    .join(", ");
};

const formatThaiAddress = (addressData: Record<string, any> = {}) => {
  const town = addressData.town ? `หมู่ ${addressData.town}` : "";
  const road = addressData.road ? `ถ.${addressData.road}` : "";
  const soi = addressData.street ? `ซอย${addressData.street}` : "";
  const city = addressData.city_label ? `แขวง${addressData.city_label}` : "";
  const district = addressData.district_label;

  return [
    addressData.no,
    addressData.name,
    town,
    soi,
    road,
    city,
    district,
    addressData.province_label,
    addressData.zipcode,
  ]
    .filter(Boolean)
    .join(" ");
};

const getDoctorOrder = (enDetail: any, supply: any[]) => {
  const doctorOrders: Record<string, any>[] = enDetail.doctor_orders || [];

  const filterAndMapOrders = (
    specificType: string,
    mapFunction: (item: Record<string, any>) => { name: string }[]
  ) =>
    doctorOrders
      .filter((item) => item.specific_type === specificType)
      .flatMap((element) => mapFunction(element));

  const labItems = filterAndMapOrders("centrallaborder", (item) => {
    const specificItemsStatus: Record<string, { name: string }> =
      item.order_summary_detail_cache.specific_items_status;

    return Object.values({ ...specificItemsStatus });
  });

  const imagingItems = filterAndMapOrders("imagingorder", (item) => {
    const specificItemsStatus: Record<string, { name: string }> =
      item.order_summary_detail_cache.specific_items_status;

    return Object.values({ ...specificItemsStatus });
  });

  const formatDrugItems = doctorOrders
    .filter((item) => (item.specific_type as string).includes("drug"))
    .flatMap((item) => {
      const summary = item.order_summary_detail_cache;
      const detail = item.summary_with_name_format || summary.order_summary_detail;

      const medicationDetails = extractMedicationInfo(detail, { removeNewlines: true }).map(
        (acc) => ({
          ...acc,
          specificTypeName: summary.specific_type.name,
        })
      );

      return medicationDetails.map((med, index) => {
        // ตรวจสอบว่าเป็น solvent และ index > 0 (เพื่อให้มีรายการก่อนหน้า)
        if (med.isSolvent && index > 0) {
          // เอา `name` จาก index ก่อนหน้ามา concat กับ full_name ของ solvent
          const previousName = medicationDetails[index - 1].full_name;

          return { ...med, for_mixing: previousName };
        }

        // ถ้าไม่ใช่ solvent หรือ index = 0 ให้คืนค่าตามเดิม
        return med;
      });
    });

  const sortedDrug = formatDrugItems.sort(
    (a, b) => SORT_DRUG_ORDER[a.specificTypeName] - SORT_DRUG_ORDER[b.specificTypeName]
  );

  const formatSupplyItems = (supply as any[])
    .map((item) => item[0] as Record<string, any>)
    .filter((item) => item.status !== "CANCELED")
    .flatMap((item) =>
      (item.items as any[]).map((acc) => ({
        name: acc.name,
        quantity_request: acc.quantity_request,
        stock_unit: acc.stock_unit,
        type: item.type,
      }))
    );

  const sortedSupply = formatSupplyItems.sort(
    (a, b) => SORT_SUPPLY_ORDER[a.type] - SORT_SUPPLY_ORDER[b.type]
  );

  const doctorNoteItems = filterAndMapOrders("doctornoteorder", (item) => [
    {
      name: item.order_summary_detail_cache.order_summary_detail,
    },
  ]);

  return {
    doctorNoteItems,
    drugItems: sortedDrug,
    imagingItems,
    labItems,
    supplyItems: sortedSupply,
  };
};

/** ====================================================  */
/**                         Utils                         */
/** ====================================================  */
const MEDICATION_NAME_REGEX = /\w+ [^(]*(?:\d|\([^)]*\))/;
const NEW_LINE_REGEX = /\n/g;

export const extractMedicationInfo = (
  input: string,
  options: { removeNewlines?: boolean } = {}
) => {
  const medicationRegex = /<b>[^<>]*<\/b>/g;
  const medicationPositions = [...input.matchAll(medicationRegex)].filter((regex) =>
    MEDICATION_NAME_REGEX.test(regex[0])
  );

  const medications: { full_name: string; htmlLabel: string; isSolvent: boolean; label: string }[] =
    [];

  const cleanText = (text: string) =>
    text
      .replaceAll(/<br\s*\/?>/gi, " ") // Convert line breaks to spaces
      .replaceAll(/<[^>]+>/g, "") // Strip HTML tags
      .replaceAll(/\s+/g, " ") // Normalize spaces
      .trim(); // Remove edge whitespace

  for (const [index, position] of medicationPositions.entries()) {
    const extractedLabelText = input.slice(
      (position.index || 0) + position[0].length,
      medicationPositions[index + 1]?.index ?? input.length
    );

    const htmlLabel = options.removeNewlines
      ? extractedLabelText.replaceAll(NEW_LINE_REGEX, "")
      : extractedLabelText.replace(/^\n/, "");

    medications.push({
      full_name: cleanText(position[0]),
      htmlLabel,
      isSolvent: htmlLabel.includes("VOLUME"),
      label: cleanText(htmlLabel),
    });
  }

  return medications;
};
