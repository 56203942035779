import React, { Component } from "react";
import { Button } from "semantic-ui-react";
import * as core from "_manager/manager-core";
import MainLayout from "react-lib/apps/common/MainLayout";
import CardPHV from "react-lib/apps/PHV/CardPHV";
import { formatDropdown } from "react-lib/utils";
import { printURL } from "react-lib/utils/printURL";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import ModalWaitingPrinting from "react-lib/apps/common/ModalWaitingPrinting";
import ModalPrintResult from "react-lib/apps/common/ModalPrintResult";
// utils
import { withInterceptor } from "react-lib/utils/hoc";
// _compat
import { vcMessenger } from "react-lib/compat/vc-websocket";
// controller
import CardBloodTransfusionHistoryController from "react-lib/apps/BLB/CardBloodTransfusionHistoryController";
import DoctorSearchBoxController from "react-lib/apps/common/DoctorSearchBoxController";
import CardMultidisciplinaryController from "react-lib/apps/PTM/CardMultiDisciplinaryController";
import SubICD10DetailController from "react-lib/apps/DPO/SubICD10DetailController";
import CardLabResultController from "react-lib/apps/LAB/CardLabResultController";
import CardImagingExamResultController from "react-lib/apps/PHV/CardImagingExamResultController";
import CardOperativeNoteViewController from "react-lib/apps/PHV/CardOperativeNoteViewController";
import CardMedicalNoteController from "react-lib/apps/PHV/CardMedicalNoteController";
import PatientSearchBoxController from "react-lib/apps/common/PatientSearchBoxController";
// Manager
import { MainPHVManager } from "react-lib/apis/manager/phv/MainPHVManager";
import BLBManager from "react-lib/apis/manager/BLBManager";
import APPManager from "react-lib/apis/manager/APPManager";
import CoreManager from "react-lib/apis/manager/CoreManager";
import DPOManager from "react-lib/apis/manager/DPOManager";
import IMEManager from "react-lib/apis/manager/IMEManager";
import LABManager from "react-lib/apis/manager/LABManager";
import LRMManager from "react-lib/apis/manager/LRMManager";
import ORMManager from "react-lib/apis/manager/ORMManager";
import PTMManager from "react-lib/apis/manager/PTMManager";
import TECManager from "react-lib/apis/manager/TECManager";
import TPDManager from "react-lib/apis/manager/TPDManager";
import REGManager from "react-lib/apis/manager/REGManager";
import UserManager from "react-lib/apis/manager/UserManager";
import { TECSessionDetail } from "react-lib/apis/master/apps/TEC/TECSession";
import CardTECSession from "react-lib/apps/TEC/CardTECSession";
import CardChartSummary from "react-lib/apps/PHV/CardChartSummary";
import { useIntl } from "react-intl";

// import PatientData from "react-lib/apps/common/PatientData";
toast.configure({ position: "top-center" });

type MainPHVState = {
  preflight_data: any | {};
  patientPanelData:
    | any
    | {
        patientData: {};
        encounterHistory: {};
        adrReaction: {};
        documentNote: undefined[];
        isEncounterIPD: boolean;
      };
  cardPHVLoading: boolean;
  forcedSelectHN: any | null;
  forcedSelectPatientId: number | null;
  forcedSelectEncounterId: number | null;
  openModalWaitingPrinting: boolean;
  modalPrintId: any | null;
  modalPrintProgress: number | any;
  modalPrintStatus: any | string;
  openModalPrintResult: boolean;
  printResult: boolean;
  patientListNumberOfPage: number;
  patientCurrentPage: number;
  doctorCurrentPage: number;
  doctorListNumberOfPage: number;
  scannedDocNumberOfPage: number;
  doctorListLoading: boolean;
  progressionNote: undefined[];
  progressNoteLoading: boolean;
  patientListLoading: boolean;
  crdSearchPatientData: undefined[];
  nationalitiesList: undefined[];
  specialitiesList: undefined[];
  patientDataAPI: any;
  // patientData: PatientData;
  tecType: string;
  admissionNoteData: {};
  admissionNoteLoading: boolean;
  consultNoteLoading: boolean;
  dischargeSummaryLoading: boolean;
  consultNoteData: undefined[];
  dischargeSummaryData: {};
  treatmentNoteData: undefined[];
  multidisciplinaryData: undefined[];
  treatmentNoteLoading: boolean;
  sensitiveNoteLoading: boolean;
  imageGalleryLoading: boolean;
  operativeNote: undefined[];
  nurseNoteData: undefined[];
  nurseNoteLoading: boolean;
  patientAssessmentData: {};
  patientAssessmentLoading: boolean;
  clinicalTermData: {};
  dischargePlanning: {};
  dischargePlanningLoading: boolean;
  reassessmentData: undefined[];
  reassessmentLoading: boolean;
  scannedDocLoading: boolean;
  scannedDocData: undefined[];
  scannedDocOptions: {};
  currentScannedDocPage: number;
  userList: undefined[];
  userListLoading: boolean;
  scanDocDoctorList: undefined[];
  numberOfPage: {};
  medicalNoteUserListLoading: boolean;
  medicalNoteUserList: undefined[];
  orderSummaryLoading: boolean;
  orderSummaryData: {};
  secretEncounterLoading: boolean;
  secretEncounterData: undefined[];
  patientOldNameData: undefined[];
  patientOldNameLoading: boolean;
  appEncounterId: null;
  sensitiveNoteData: { sensitive_note: "" }
  imageGalleryData: {}
  openCardPHV: boolean;
  openCardChartSummary: boolean;
  doctorList: any[];
  // ChartSummary
  isNurse: boolean;
  enabledChartSummary: boolean;
  // isOPDEncounter: boolean;
  // isIPDEncounter: boolean;
  csLoading: boolean;
  allDoctorList: any[];
  allDivisionList: any[];
  allLabDivisionList: any[];
  csLabList: any[];
  csEncounterList: any;
  csDianosisList: any[];
  csPendingOrderList: any[];
  csMedicationList: any[];
  csPatientCareList: any[];
  csReplyConsultList: any[];
  csProcedureDetailList: any;
  csADRData: any;
  isShowTECSession: boolean;
  selectedPatientData: any;
  selectedSessionId: any;
  multidisciplinaryLoading: boolean;
  userPermission: any;
};
class MainPHV extends Component<
  {
    apiToken: string | null;
    division?: number | null;
    django?: any;
    // function
    onEvent?: any;
    setProp?: any;
    // controller
    proxyController?: any;
    cardANSBillController?: any;
    // CommonInterface
    errorMessage?: any;
    successMessage?: any;
    buttonLoadCheck?: any;
    // options
    masterOptions?: any;
    masterData?: any;
    ChoiceTriage?: any;
    // seq
    runSequence?: any;
    AssessmentSequence?: any;
    HistoryCentralLabSequence?: any;
    ORPostOperationSequence?: any;
    ORHistorySequence?: any;
    ORRequestSequence?: any;
    PerioperativeNursingSequence?: any;
    PreOperationSequence?: any;
    ImagingResultSequence?: any;
    PreAssessmentSequence?: any;
    ReAssessmentSequence: any;
    selectedDoctor?: any;
    PreAnestheticSequence?: any;
    selectedAnesthesiologist?: any;
    AnestheticHistorySequence?: any;
    AnesRecordSequence?: any;
    PACURecordSequence?: any;
    PeriDataSheetSequence?: any;
    AnesComplicationSequence?: any;
    billDrugOrder?: any;
    selectedEmr?: any;
    selectedProgressCycle?: any;
    AnesBillSequence?: any;
    languageUX?: any;
    // SearchBox
    searchedItemListWithKey?: any;
    // data
    emrId?: any;
    preAssessmentOptions?: any;
    preAssessmentActionLog?: any;
    reAssessmentOptions?: any;
    subICDController?: any;
    selectedPatient?: any;
    selectedEncounter?: any;
    loadingStatus?: any;
    userTokenize?: any;
    modXrayDetail?: any;
    clinicalFindingId?: any;
    clinicalFindingIndex?: any;
    clinicalFindingList?: any;
    organ?: any;
    clinicaltags?: any;
    filterClinicalFindingIds?: any;
    showContentOnly?: boolean;
    selectedRowEncounter?: any;
    filterOnSelectPatient?: boolean;
    encounterPatientOptimize?: boolean;
    notRenameTitle?: boolean;
    // Nursing Diagnosis
    divisionType?: any;
    medReconcileCheck?: any;
    medReconcileIndex?: any;
    NursingDiagnosisSequence?: any;
  },
  MainPHVState
> {
  cardChartSummaryRef: any;
  MainPHVManager: any;
  appManager: any;
  blbManager: any;
  coreManager: any;
  dpoManager: any;
  imeManager: any;
  labManager: any;
  lrmManager: any;
  ormManager: any;
  ptmManager: any;
  tecManager: any;
  userManager: any;
  regManager: any;
  tpdManager: any;
  crdMultiDisciplinaryController: any;
  subICD10DetailController: any;
  cardLabResultController: any;
  cardImagingExamResultController: any;
  cardBloodTransfusionHistoryController: any;
  doctorSearchBoxController: any;
  cardOperativeNoteViewController: any;
  cardMedicalNoteController: any;
  patientSearchController: any;
  formatDropdown: any;
  // not implement yet /core/document/note
  constructor(props: any) {
    super(props);

    this.cardChartSummaryRef = React.createRef();

    let search = window.location.search;
    let params = new URLSearchParams(search);
    let ishavehn = params.get("hn");
    // Can't set state here , need check patientId
    // let selectedEncounterId = Number(params.get("encounterId"));

    this.state = {
      preflight_data: {},
      patientListNumberOfPage: 0,
      patientCurrentPage: 1,
      doctorCurrentPage: 1,
      doctorListNumberOfPage: 0,
      scannedDocNumberOfPage: 0,
      doctorListLoading: false,
      cardPHVLoading: false,
      progressionNote: [],
      progressNoteLoading: false,
      patientListLoading: false,
      crdSearchPatientData: [],
      nationalitiesList: [],
      specialitiesList: [],
      forcedSelectHN: null,
      forcedSelectPatientId: null,
      forcedSelectEncounterId: null,
      //PatientPanel
      patientPanelData: {
        patientData: {},
        encounterHistory: {},
        adrReaction: {},
        documentNote: [],
        isEncounterIPD: true,
      },
      patientDataAPI: {},
      // patientData: new PatientData(),
      tecType: "",
      admissionNoteData: {},
      admissionNoteLoading: false,
      consultNoteLoading: false,
      dischargeSummaryLoading: false,
      consultNoteData: [],
      dischargeSummaryData: {},
      treatmentNoteData: [],
      multidisciplinaryData: [],
      treatmentNoteLoading: false,
      sensitiveNoteLoading: false,
      imageGalleryLoading: false,
      operativeNote: [],
      nurseNoteData: [],
      nurseNoteLoading: false,
      patientAssessmentData: {},
      patientAssessmentLoading: false,
      clinicalTermData: {},
      dischargePlanning: {},
      dischargePlanningLoading: false,
      reassessmentData: [],
      reassessmentLoading: false,
      scannedDocLoading: false,
      scannedDocData: [],
      scannedDocOptions: {},
      currentScannedDocPage: 1,
      userList: [],
      userListLoading: false,
      scanDocDoctorList: [],
      numberOfPage: {},
      medicalNoteUserListLoading: false,
      medicalNoteUserList: [],
      orderSummaryLoading: false,
      orderSummaryData: {},
      secretEncounterLoading: false,
      secretEncounterData: [],
      patientOldNameData: [],
      patientOldNameLoading: false,
      openModalWaitingPrinting: false,
      modalPrintId: null,
      modalPrintProgress: 0,
      modalPrintStatus: "",
      openModalPrintResult: false,
      printResult: false,
      appEncounterId: null,

      // ChartSummary
      isNurse: false,
      enabledChartSummary: false,
      openCardPHV: ishavehn ? false : true,
      openCardChartSummary: ishavehn ? true : false,
      // isOPDEncounter: true,
      // isIPDEncounter: false,
      csLoading: false,
      allDoctorList: [],
      allDivisionList: [],
      allLabDivisionList: [],
      csLabList: [],
      csEncounterList: {},
      csDianosisList: [],
      csPendingOrderList: [],
      csMedicationList: [],
      csPatientCareList: [],
      csReplyConsultList: [],
      csProcedureDetailList: null,
      csADRData: null,
    };
    this.MainPHVManager = new MainPHVManager(
      this.props.apiToken,
      this.props.division
    );
    this.appManager = new APPManager(this.props.apiToken);
    this.blbManager = new BLBManager(this.props.apiToken);
    this.coreManager = new CoreManager(this.props.apiToken);
    this.dpoManager = new DPOManager(this.props.apiToken);
    this.formatDropdown = formatDropdown.bind(this);
    this.imeManager = new IMEManager(this.props.apiToken);
    this.labManager = new LABManager(this.props.apiToken);
    this.lrmManager = new LRMManager(this.props.apiToken);
    this.ormManager = new ORMManager(this.props.apiToken);
    this.ptmManager = new PTMManager(this.props.apiToken);
    this.tecManager = new TECManager(this.props.apiToken);
    this.userManager = new UserManager(this.props.apiToken);
    this.regManager = new REGManager(this.props.apiToken);
    this.tpdManager = new TPDManager(this.props.apiToken);
    this.crdMultiDisciplinaryController = new CardMultidisciplinaryController({
      coreManager: this.coreManager,
    });
    this.subICD10DetailController = new SubICD10DetailController(
      this.coreManager,
      this.dpoManager
    );
    this.cardLabResultController = new CardLabResultController(this.labManager);
    this.cardImagingExamResultController = new CardImagingExamResultController(
      this.imeManager
    );
    this.cardBloodTransfusionHistoryController =
      new CardBloodTransfusionHistoryController({
        blbManager: this.blbManager,
        userManager: this.userManager,
      });
    this.doctorSearchBoxController = new DoctorSearchBoxController({
      coreManager: this.coreManager,
    });
    this.cardOperativeNoteViewController = new CardOperativeNoteViewController({
      ormManager: this.ormManager,
      mainPHVManager: this.MainPHVManager,
    });
    this.cardMedicalNoteController = new CardMedicalNoteController({
      mainPHVManager: this.MainPHVManager,
    });
    this.patientSearchController = new PatientSearchBoxController(
      this.coreManager,
      this.regManager
    );
  }
  async componentDidMount() {
    console.log("CardPHVWrapper props.apiToken", this.props.apiToken);

    const preflight_data = this.props.django
      ? this.props.django
      : await core.preflight();

    if (!this.props.notRenameTitle) {
      window.document.title = "MainPHV";
    }

    const isNurse = (preflight_data?.user?.role_types || []).some((type: any) =>
      type.includes("NURSE")
    );
    this.setState({ isNurse });

    this.setState({ preflight_data });

    const params = {
      config_PTM_ENABLE_CHART_SUMMARY: null,
      rolegroup_CAN_EDIT_SECRET_DOCUMENT_CODE: false,
    };
    const [ptmEnabledChartSummary, error] =
      await this.userManager.getUserPermission(params);

    if (ptmEnabledChartSummary) {
      this.setState({
        enabledChartSummary: ptmEnabledChartSummary.config_PTM_ENABLE_CHART_SUMMARY,
        userPermission: ptmEnabledChartSummary,
      });
    } else {
      this.setState({ enabledChartSummary: false, userPermission: null });
    }

    this.MainPHVManager.getClinialType().then((data: any) => {
      let options = data[0].map((item: any) => {
        return { text: item.name, value: item.code };
      });
      this.setState({
        patientPanelData: {
          ...this.state.patientPanelData,
          documentNote: options,
        },
      });
    });
    let search = new URLSearchParams(window.location.search);
    let selectedPatientId = Number(search.get("patient"));
    let selectedEncounterId = Number(search.get("encounterId"));

    let hn = search.get("hn");

    // Chart Summary URL &hn=CXXXXX
    if (
      hn &&
      !selectedPatientId &&
      ptmEnabledChartSummary.config_PTM_ENABLE_CHART_SUMMARY
    ) {
      this.handleLoadCSPatient(hn);
    }

    // PHV2 &patient=xxxx, or &patient=xxxx&encounterId=xxx get HN
    if (selectedPatientId) {
      this.setState({ cardPHVLoading: true });
      const [data, error] = await this.MainPHVManager.getPatientData(
        selectedPatientId
      );
      if (!!error) {
        this.setState({
          forcedSelectHN: data.hn,
          forcedSelectPatientId: selectedPatientId,
        });

        if (selectedEncounterId !== 0) {
          this.setState({ forcedSelectEncounterId: selectedEncounterId });
        }
      }
      // this.setState({ cardPHVLoading: false });
    }

    vcMessenger.onMessage(this.printTracking);

    this.handleGetCSMasterList();
  }

  componentWillUnmount() {
    vcMessenger.removeOnMessage(this.printTracking)
  }

  printTracking = async (msg: any) => {
    // Status:
    // COMPLETED
    // FAILED
    // IN_PROGESS
    // WAITING
    if (msg.status === "WAITING" && !this.state.openModalWaitingPrinting) {
      this.setState({
        openModalWaitingPrinting: true,
        modalPrintId: msg.task_id,
        modalPrintProgress: 0,
        modalPrintStatus: msg.status,
      });
      return;
    }
    if (msg.status === "IN_PROGRESS") {
      this.setState({
        openModalWaitingPrinting: true,
        modalPrintId: msg.task_id,
        modalPrintProgress: msg.progress,
        modalPrintStatus: msg.status,
      });
      return;
    }
    if (msg.status !== "IN_PROGRESS") {
      // Both Complete and failed
      this.setState({ openModalWaitingPrinting: false });
    }
    if (msg.status === "FAILED") {
      // Both Complete and failed
      this.setState({
        openModalPrintResult: true,
        printResult: false,
      });
    } else if (msg.status === "COMPLETED") {
      this.preparePrintComplete(msg.task_id);
    }
  }

  preparePrintComplete = async (id: number) => {
    let params = {
      task_id: id,
    };
    const [printOrderSummary, printOrderSummaryError] =
      await this.MainPHVManager.getTaskTrackingPrintResult(params);
    // Check Error API
    if (printOrderSummaryError) {
      this.setState({
        openModalPrintResult: true,
        printResult: false,
      });
    }
    // Check Success from Server
    if (
      printOrderSummary &&
      printOrderSummary.data &&
      printOrderSummary.data.success
    ) {
      // Success prepare or server printing success
      if (
        printOrderSummary &&
        printOrderSummary.data &&
        printOrderSummary.data.print_at
      ) {
        if (printOrderSummary.data.print_at === "PDF") {
          if (
            printOrderSummary &&
            printOrderSummary.headers &&
            printOrderSummary.headers["x-local-print"]
          ) {
            printURL(
              "/users/apis/local-print/" +
                printOrderSummary.headers["x-local-print"]
            );
            return;
          }
        }
      }
      this.setState({
        openModalPrintResult: true,
        printResult: true,
      });
    } else {
      // Failed to prepare
      this.setState({
        openModalPrintResult: true,
        printResult: false,
      });
    }
  };
  getOffset = (limit: number, currentPage: number) => {
    return currentPage ? (currentPage - 1) * limit : 0;
  };
  getProgressionNoteData = async ({
    encounterType,
    medicalStartDate,
    medicalEndDate,
    medicalRecordId,
    currentPage,
  }: any = {}) => {
    if (!medicalRecordId) {
      return;
    }
    let limit = 10;
    let offset = this.getOffset(limit, currentPage);
    let params: Record<string, any> =
      encounterType === "IPD"
        ? {
            sort_descending: true,
            limit,
            offset,
          }
        : {
            sort_descending: true,
            limit,
            offset,
            emr: medicalRecordId,
          };
    if (medicalStartDate) {
      params.start_date = medicalStartDate;
    }
    if (medicalEndDate) {
      params.end_date = medicalEndDate;
    }
    let emrId = medicalRecordId;
    this.setState({ progressNoteLoading: true });
    const [progressionNoteData, progressionNoteError, numberOfPage] =
      await this.MainPHVManager.getProgressionNote(
        emrId,
        params,
        encounterType === "IPD"
      );
    if (progressionNoteError && typeof progressionNoteError === "string") {
      toast.error(progressionNoteError);
    }
    if (numberOfPage) {
      this.setNumberOfPage("progressionNote", numberOfPage);
    }
    this.setState({
      progressionNote: progressionNoteData,
      progressNoteLoading: false,
    });
  };
  handleGetEncounterDetail = async (encounterId: any) => {
    const [encounterDetailResp, encounterDetailErr] = await this.MainPHVManager.getEncounterDetail(encounterId);
    if (encounterDetailErr) {
      return null;
    } else {
      return encounterDetailResp;
    }
  };
  handleGetPatientByHN = async ({ hn }: any = {}) => {
    const [searchPatientData, searchPatientError] =
      await this.MainPHVManager.getPatientByHN(hn);
    return [searchPatientData, searchPatientError];
  };
  handleGetPatientList = async ({
    hn,
    fullName,
    citizenId,
    nationality,
    patientCurrentPage,
  }: any = {}) => {
    if (!patientCurrentPage) {
      patientCurrentPage = this.state.patientCurrentPage;
    }
    let limit = 40;
    let params: Record<string, any> = {
      is_old_name_search: true,
      offset: (patientCurrentPage - 1) * limit,
      limit,
    };
    if (hn) {
      params.hn = hn;
    }
    if (fullName) {
      params.name_search = fullName;
    }
    if (citizenId) {
      params.citizen_passport = citizenId;
    }
    if (nationality) {
      params.nationality = nationality;
    }
    this.setState({ patientListLoading: true });
    const [searchPatientData, searchPatientError, numberOfPage] =
      await this.MainPHVManager.getPatientList(params);
    //TODO: Handle searchPatientError
    if (numberOfPage) {
      this.setState({ patientListNumberOfPage: numberOfPage });
    }
    this.setState({
      patientListLoading: false,
      crdSearchPatientData: searchPatientData,
    });
  };
  handleGetSearchDoctor = async ({ doctorName }: any = {}) => {
    const [data, error] = await this.MainPHVManager.getSearchDoctor({
      doctorName,
    });
    if (error) {
      toast.error(error);
    }
    return [data, error];
  };
  handleSearchDoctorList = async (
    name: string,
    div: any,
    doctorCurrentPage: number
  ) => {
    if (!doctorCurrentPage) {
      doctorCurrentPage = this.state.doctorCurrentPage;
    }
    let limit = 40;
    let params: Record<string, any> = {
      offset: (doctorCurrentPage - 1) * limit,
      limit,
    };
    if (name) {
      params.name_code = name;
    }
    if (div) {
      params.specialty = div;
    }
    this.setState({ doctorListLoading: true });
    const [doctorListData, doctorListError, numberOfPage] =
      await this.MainPHVManager.getDoctorList(params);
    if (doctorListError) {
      toast.error(doctorListError);
    }
    if (numberOfPage) {
      this.setState({ doctorListNumberOfPage: numberOfPage });
    }
    // TODO: handle error doctorListError
    this.setState({ doctorList: doctorListData, doctorListLoading: false });
  };
  handlePatientPaginationChange = async ({
    hn,
    fullName,
    citizenId,
    nationality,
    patientCurrentPage,
  }: any = {}) => {
    this.setState({ patientCurrentPage });
    await this.handleGetPatientList({
      hn,
      fullName,
      citizenId,
      nationality,
      patientCurrentPage,
    });
  };
  handleDoctorPaginationPageChange = async (
    doctorName: string,
    speciality: any,
    page: number
  ) => {
    this.setState({ doctorCurrentPage: page });
    await this.handleSearchDoctorList(doctorName, speciality, page);
  };
  handleScannedDocPaginationChange = ({
    docStartDate,
    docEndDate,
    scanStartDate,
    scanEndDate,
    expiredStartDate,
    expiredEndDate,
    documentCategory,
    scanDivision,
    ownerDivision,
    docNo,
    scanUser,
    encounterDoctor,
    hnPatient,
    currentScannedDocPage,
  }: any = {}) => {
    this.setState({ currentScannedDocPage });
    this.handleGetScannedDocument({
      docStartDate,
      docEndDate,
      scanStartDate,
      scanEndDate,
      expiredStartDate,
      expiredEndDate,
      documentCategory,
      scanDivision,
      ownerDivision,
      docNo,
      scanUser,
      encounterDoctor,
      hnPatient,
      currentScannedDocPage,
    });
  };
  handleGetNationality = async () => {
    const [nationalitiesData, nationalitiesError] =
      await this.MainPHVManager.getNationalities();
    //TODO: Handle nationalitiesError
    if (nationalitiesError) {
      toast.error(nationalitiesError);
    }
    let nationalitiesList = formatDropdown(
      nationalitiesData,
      "full_name",
      "id"
    );
    let list = nationalitiesList.map((item) => {
      delete item.is_active;
      return item;
    });
    this.setState({ nationalitiesList: list });
  };

  // handlePatientDataChange = () => {
  //   this.setState({ patientData: this.state.patientData });
  // };

  handleOnFilterDate = async ({
    patientId,
    doctorId,
    startDate,
    endDate,
    divisionId,
    filter,
    limit,
    offset,
    investigation,
  }: any = {}) => {
    console.log(" handleOnFilterDate ==========");
    let encounterHistoryParams: Record<string, any> = {
      limit: limit,
      offset: offset,
      principal_diagnosis: "true",
    };
    if (patientId) {
      encounterHistoryParams.patient = patientId;
    }
    if (startDate) {
      encounterHistoryParams.start = startDate;
      encounterHistoryParams.from_date = startDate;
    }
    if (endDate) {
      encounterHistoryParams.end = endDate;
      encounterHistoryParams.to_date = endDate;
    }
    if (doctorId) {
      encounterHistoryParams.doctor = doctorId;
    }
    if (divisionId) {
      encounterHistoryParams.division = divisionId;
    }
    if (filter) {
      encounterHistoryParams.encounter_type = filter["encounterType"];
      encounterHistoryParams.code = filter["noteType"];
    }
    if (investigation) {
      encounterHistoryParams.investigation = investigation;
    }
    this.setState({ cardPHVLoading: true });
    const [patientData, patientError] =
      await this.MainPHVManager.getPatientData(patientId);
    if (this.state.forcedSelectEncounterId) {
      encounterHistoryParams = {
        limit: 1,
        offset: 0,
        patient: patientId,
        encounter_type: ["IPD", "OPD", "SS", "ER"],
        encounter: this.state.forcedSelectEncounterId,
      };
    }
    console.log(
      " forcedSelectEncounterId ======",
      this.state.forcedSelectEncounterId
    );

    this.setState({ patientDataAPI: patientData });

    const api = this.props.encounterPatientOptimize
      ? this.MainPHVManager.getEncounterPatientOptimized
      : this.MainPHVManager.getEncounterHistory;

    const [encounterHistory, encounterError] = await api(
      encounterHistoryParams
    );

    if (encounterHistory.items && this.props.encounterPatientOptimize) {
      encounterHistory.items = await Promise.all(
        encounterHistory.items.reverse().map((item: any) =>
          this.MainPHVManager.getListTreatmentIssue({
            encounter: item.id,
          }).then(([res]: any) => ({
            ...item,
            date: item.created,
            treatmentIssue: res?.items || [],
          }))
        )
      );
    }

    const [adrReaction, adrReactionError] =
      await this.MainPHVManager.getADRReaction(patientId);
    // TODO: Handle Error
    this.setState({
      patientPanelData: {
        patientData: patientData,
        encounterHistory: encounterHistory,
        adrReaction: adrReaction,
        documentNote: this.state.patientPanelData.documentNote,
      },
      cardPHVLoading: false,
    });
    console.log(" cardPHVLoading: false: ");
  };
  handleGetEncounterList = async ({
    patientId,
    doctorId,
    startDate,
    endDate,
    divisionId,
    filter,
    limit,
    offset,
  }: any = {}) => {
    console.log(" handleGetEncounterList ===========");
    let encounterHistoryParams: Record<string, any> = {
      limit: limit,
      offset: offset,
    };
    if (patientId) {
      encounterHistoryParams.patient = patientId;
    }
    if (startDate) {
      encounterHistoryParams.start = startDate;
    }
    if (endDate) {
      encounterHistoryParams.end = endDate;
    }
    if (doctorId) {
      encounterHistoryParams.doctor = doctorId;
    }
    if (divisionId) {
      encounterHistoryParams.division = divisionId;
    }
    if (filter) {
      encounterHistoryParams.encounter_type = filter["encounterType"];
      encounterHistoryParams.code = filter["noteType"];
    }
    this.setState({ cardPHVLoading: true });
    const [encounterHistory, encounterError] =
      await this.MainPHVManager.getEncounterHistory(encounterHistoryParams);
    // TODO: Handle Error
    this.setState({
      patientPanelData: {
        patientData: this.state.patientPanelData.patientData, // PatientInfo
        encounterHistory: encounterHistory,
        adrReaction: this.state.patientPanelData.adrReaction,
        documentNote: this.state.patientPanelData.documentNote,
      },
      cardPHVLoading: false,
    });
    console.log(" cardPHVLoading: false: ");
  };
  handleGetSpeciality = async () => {
    const [specialityData, specialityError] =
      await this.MainPHVManager.getSpeiciality();
    if (specialityError) {
      toast.error(specialityError);
    }
    let specialitiesList = formatDropdown(specialityData, "name", "id");
    let list = specialitiesList.map((item) => {
      delete item.is_active;
      delete item.display_seq;
      return item;
    });
    this.setState({ specialitiesList: list });
  };
  handleGetAdmissionNote = async ({
    medicalRecordId,
    encounterType,
  }: any = {}) => {
    if (!medicalRecordId) {
      return;
    }
    if (encounterType !== "IPD") {
      this.setState({ admissionNoteData: {} });
      return;
    }
    let emr = medicalRecordId;
    this.setState({ admissionNoteLoading: true });
    const [admissionNoteData, admissionNoteError] =
      await this.MainPHVManager.getAdmissionNote(emr);
    if (admissionNoteError) {
      toast.error(admissionNoteError);
    }
    this.setState({ admissionNoteData, admissionNoteLoading: false });
  };
  handleGetDischargeSummary = async ({
    medicalRecordId,
    encounterType,
  }: any = {}) => {
    let emr = medicalRecordId;
    this.setState({ dischargeSummaryLoading: true });
    const [dischargeSummaryData, dischargeSummaryError] =
      await this.MainPHVManager.getDischargeSummary(emr);
    if (dischargeSummaryError.dischargeSummary !== null) {
      dischargeSummaryData.status = "NODISCHARGESUMMARY";
    }
    // for (var keys in dischargeSummaryError) {
    //   if (dischargeSummaryError[keys]) {
    //     if (typeof dischargeSummaryError[keys] === "object") {
    //       for (var subkey in dischargeSummaryError[keys]) {
    //         toast.error(keys + ": " + dischargeSummaryError[keys][subkey]);
    //       }
    //     } else {
    //       toast.error(keys + ": " + dischargeSummaryError[keys]);
    //     }
    //   }
    // }
    this.setState({ dischargeSummaryData, dischargeSummaryLoading: false });
  };
  handleGetDischargeSummaryOnlyData = async ({
    medicalRecordId,
    encounterType,
  }: any = {}) => {
    if (!medicalRecordId || encounterType !== "IPD") {
      return [null, null];
    }
    // let emr = 144202;
    let emr = medicalRecordId;
    const [dischargeSummaryData, dischargeSummaryError] =
      await this.MainPHVManager.getDischargeSummary(emr);
    // for (var keys in dischargeSummaryError) {
    //   if (dischargeSummaryError[keys]) {
    //     if (typeof dischargeSummaryError[keys] === "object") {
    //       for (var subkey in dischargeSummaryError[keys]) {
    //         toast.error(keys + ": " + dischargeSummaryError[keys][subkey]);
    //       }
    //     } else {
    //       toast.error(keys + ": " + dischargeSummaryError[keys]);
    //     }
    //   }
    // }
    return [dischargeSummaryData, dischargeSummaryError];
  };
  handleGetConsultNote = async ({ encounterId }: any = {}) => {
    if (!encounterId) {
      return;
    }
    let params: Record<string, any> = {};
    if (encounterId) {
      params.encounter_id = encounterId;
    }
    this.setState({ consultNoteLoading: true });
    const [consultNoteData, consultNoteError] =
      await this.MainPHVManager.getDoctorConsultOrder(params);
    if (consultNoteError) {
      toast.error(consultNoteError);
    }
    this.setState({ consultNoteData, consultNoteLoading: false });
  };
  handleGetTreatmentResult = async ({
    medicalStartDate,
    medicalEndDate,
    encounterId,
    medicalNoteDoctor,
    medicalNotePerformUser,
    currentPage,
  }: any = {}) => {
    let limit = 10;
    let offset = this.getOffset(limit, currentPage);
    if (!encounterId) {
      return;
    }
    let params: Record<string, any> = {
      exclude_draft: true,
      sort_descending: true,
      limit,
      offset,
    };
    if (encounterId) {
      params.encounter_id = encounterId;
    }
    if (medicalStartDate) {
      params.from_date = medicalStartDate;
    }
    if (medicalEndDate) {
      params.to_date = medicalEndDate;
    }
    if (medicalNoteDoctor) {
      params.order_by = medicalNoteDoctor;
    }
    if (medicalNotePerformUser) {
      params.order_perform_by = medicalNotePerformUser;
    }
    this.setState({ treatmentNoteLoading: true });
    const [treatmentNoteData, treatmentNoteError, numberOfPage] =
      await this.MainPHVManager.getTreatmentNote(params);
    for (var keys in treatmentNoteError) {
      if (treatmentNoteError[keys]) {
        if (typeof treatmentNoteError[keys] === "object") {
          for (var subkey in treatmentNoteError[keys]) {
            toast.error(keys + ": " + treatmentNoteError[keys][subkey]);
          }
        } else {
          toast.error(keys + ": " + treatmentNoteError[keys]);
        }
      }
    }
    if (numberOfPage) {
      this.setNumberOfPage("treatmentNote", numberOfPage);
    }
    this.setState({ treatmentNoteData, treatmentNoteLoading: false });
  };
  handleGetSensitiveNote = async ({ medicalRecordId }: any = {}) => {
    if (!medicalRecordId) {
      return;
    }
    let emrId = medicalRecordId;
    this.setState({ sensitiveNoteLoading: true });
    const [sensitiveNoteData, sensitiveNoteError] =
      await this.MainPHVManager.getSensitiveNote(emrId);
    if (Object.keys(sensitiveNoteError).length > 0) {
      if (!sensitiveNoteError.detail) {
        toast.error(sensitiveNoteError);
      }
    }
    this.setState({ sensitiveNoteData, sensitiveNoteLoading: false });
  };
  handleGetImageGallery = async ({ medicalRecordId }: any = {}) => {
    if (!medicalRecordId) {
      return;
    }
    let emrId = medicalRecordId;
    this.setState({ imageGalleryLoading: true });
    const [_data, err] = await this.dpoManager.getMedicalRecordGallery(emrId);
    this.setState({ imageGalleryData: _data, imageGalleryLoading: false });
  };
  handleEncounterFilterChange = async ({
    filter,
    page,
    limit,
    forcedSelectEncounterId,
  }: any = {}) => {
    console.log(
      " handleEncounterFilterChange called forcedSelectEncounterId: ",
      forcedSelectEncounterId
    );
    console.log(
      " handleEncounterFilterChange called this.state.patientPanelData.patientData.id): ",
      this.state.patientPanelData.patientData.id
    );

    if (this.state.patientPanelData.patientData.id) {
      let encounterHistoryParams: Record<string, any> = {
        patient: this.state.patientPanelData.patientData.id,
        limit: limit,
        offset: page * limit,
      };
      if (this.state.patientPanelData.startDateEncounter) {
        encounterHistoryParams.start =
          this.state.patientPanelData.startDateEncounter;
      }
      if (this.state.patientPanelData.endDateEncounter) {
        encounterHistoryParams.end =
          this.state.patientPanelData.endDateEncounter;
      }
      if (filter) {
        encounterHistoryParams.encounter_type = filter["encounterType"];
        encounterHistoryParams.code = filter["noteType"];
      }
      if (forcedSelectEncounterId) {
        encounterHistoryParams = {
          patient: this.state.patientPanelData.patientData.id,
          limit: 1,
          offset: 0,
        };
        encounterHistoryParams.encounter = forcedSelectEncounterId;
        encounterHistoryParams.encounter_type = ["OPD", "IPD", "ER", "SS"];
      }
      console.log("encounterHistoryParams ", encounterHistoryParams);
      const [encounterHistory, encounterError] =
        await this.MainPHVManager.getEncounterHistory(encounterHistoryParams);
      // TODO: Handle encounterError
      this.setState({
        patientPanelData: {
          ...this.state.patientPanelData,
          encounterHistory: encounterHistory,
        },
      });
    }
  };
  handleGetEMRSummaryData = async ({ patientId, encounterId }: any = {}) => {
    let params: Record<string, any> = {};
    if (patientId) {
      params.patientId = patientId;
    }
    if (encounterId) {
      params.encounterId = encounterId;
    }
    const [emrSummaryData, emrSummaryError] =
      await this.MainPHVManager.getEMRSummary(params);
    return [emrSummaryData, emrSummaryError];
  };
  handleGetAdverseReaction = async ({ patientId }: any = {}) => {
    let params: Record<string, any> = {exclude_unused: true, severe_first: true};
    if (patientId) {
      params.patient = patientId;
    }
    const [adrData, adrError] =
      await this.MainPHVManager.getAdverseReaction(params);
    return [adrData, adrError];
  };
  handleGetEMRSummaryPrint = async ({ patientId, encounterId }: any = {}) => {
    let params: Record<string, any> = {
      pdf: true,
    };
    if (patientId) {
      params.patientId = patientId;
    }
    if (encounterId) {
      params.encounterId = encounterId;
    }
    const [emrSummaryData, emrSummaryError] =
      await this.MainPHVManager.getEMRSummaryPrint(params);
    // TODO: handle emrSummaryError
  };
  handleGetmedicationRecord = async ({ emrId, date, type }: any = {}) => {
    let params: Record<string, any> = {};
    if (emrId) {
      params.emrId = emrId;
    }
    if (date) {
      params.date = date;
    }
    if (type) {
      params.type = type;
    }
    const [medicationData, medicationError] =
      await this.MainPHVManager.getMedicationRecordSummary(params);
    return [medicationData, medicationError];
  };

  handleGetMedReconciliation = async (params: any) => {
    const [medReconcileData, medReconcileError] =
      await this.MainPHVManager.getMedReconciliation(params);
    return [medReconcileData, medReconcileError];
  };

  handleGetMedReconciliationDetail = async (
    medReconcileId: number,
    params: any
  ) => {
    const [medReconcileDetail, medReconcileError] =
      await this.MainPHVManager.getMedReconciliationWithId(
        medReconcileId,
        params
      );
    return [medReconcileDetail, medReconcileError];
  };

  handleGetMedReconciliationLog = async (params: any) => {
    const [medReconcileLog, medReconcileError] =
      await this.MainPHVManager.getMedReconciliationLog(params);
    return [medReconcileLog, medReconcileError];
  };

  handleMedicationRecordPrint = async ({ encounterId }: any = {}) => {
    let params: Record<string, any> = {
      pdf: true,
    };
    if (encounterId) {
      params.encounter_id = encounterId;
    }
    const [medicationPrintSuccess, medicationPrintError] =
      await this.MainPHVManager.getMedicationRecordSummaryPrint(params);
    if (medicationPrintSuccess) {
      return toast.success("Success !");
    }
    return toast.success("เกิดข้อผิดพลาด");
  };
  handleGetHomeMedDetail = async ({ emrId, encounterId }: any = {}) => {
    const [homeMedDetail, homeMedError] = await this.MainPHVManager.getAllDrugOrderItems({
      emrId,
      encounterId,
    });
    return [homeMedDetail, homeMedError];
  };
  handleGetLabCode = async ({ patientId, divisionList }: any = {}) => {
    let params: Record<string, any> = { limit: 300 };
    if (patientId) {
      params.patient = patientId;
    }
    if (divisionList) {
      params.lab_division = divisionList;
    }
    const [division, divisionError] =
      await this.MainPHVManager.getCenterLabDivision();
    const [filterResult, filterResultError] =
      await this.MainPHVManager.getCentalLabTestResultFitlerList(params);
    return [
      {
        division: division,
        filterResult: filterResult,
      },
      { divisionError, filterResultError },
    ];
  };
  handleGetLabDetail = async ({
    patientId,
    LabCode,
    startDate,
    stopDate,
    username,
    password,
  }: any = {}) => {
    let params: Record<string, any> = {
      columns: [],
      items: [],
    };
    if (startDate) {
      params.from_date = startDate;
    }
    if (stopDate) {
      params.to_date = stopDate;
    }
    if (LabCode) {
      params.products = LabCode;
    }
    if (patientId) {
      params.patient = patientId;
    }
    if (username) {
      params.username = username;
    }
    if (password) {
      params.password = password;
    }
    const [centralLabResultComparable, centralLabResultComparableError] =
      await this.MainPHVManager.getCentalLabTestResultComparable(params);
    return [
      { labCompare: centralLabResultComparable },
      centralLabResultComparableError,
    ];
  };
  handleGetInterfaceSummaryReportDetail = async ({ cloi }: any = {}) => {
    let params = {
      cloi: cloi,
    };
    const [data, error] =
      await this.MainPHVManager.getInterfaceSummaryReportDetail(params);
    return [data, error];
    // TODO: remove interfaceSummaryReportDetailData to data
    // let a = {"ono":"191062097","reports":[{"report_by":"017594","description":".","report_datetime":"06/09/2019 00:29:09","report_pdf":"http://10.58.249.70/media/patient/lab_summary_document/191062097_20190906_002821_.pdf"}]}
    // return [a, error];
  };
  handleGetImagingExamResult = async ({ encounterId }: any = {}) => {
    let params: Record<string, any> = {};
    if (encounterId) {
      params.encounterId = encounterId;
    }
    const [medicationData, medicationError] =
      await this.MainPHVManager.getImagingExamResult(params);
    return [medicationData, medicationError];
  };
  setNumberOfPage = (field: string, numberOfPage: number) => {
    this.setState({
      numberOfPage: {
        ...this.state.numberOfPage,
        [field]: numberOfPage,
      },
    });
  };
  handleGetNurseNote = async ({
    nurseRecordStartDate,
    nurseRecordEndDate,
    encounterId,
    currentPage,
  }: any = {}) => {
    let limit = 100;
    let offset = this.getOffset(limit, currentPage);
    if (!encounterId) {
      return;
    }
    let params: Record<string, any> = {
      // sort_descending: true,
      limit,
      offset,
    };
    if (encounterId) {
      params.encounter = encounterId;
    }
    if (nurseRecordStartDate) {
      params.from_date = nurseRecordStartDate;
    }
    if (nurseRecordEndDate) {
      params.to_date = nurseRecordEndDate;
    }
    this.setState({ nurseNoteLoading: true });
    const [nurseNoteData, nurseNoteError, numberOfPage] =
      await this.MainPHVManager.getNurseNote(params);
    if (nurseNoteError) {
      toast.error(nurseNoteError);
    }
    if (numberOfPage) {
      this.setNumberOfPage("nurseNote", numberOfPage);
    }
    this.setState({ nurseNoteLoading: false, nurseNoteData: nurseNoteData });
  };
  handlePrintNurseNote = async ({
    nurseRecordStartDate,
    nurseRecordEndDate,
    encounterId,
    currentPage,
  }: any = {}) => {
    let limit = 10;
    let offset = this.getOffset(limit, currentPage);
    if (!encounterId) {
      return;
    }
    let params: Record<string, any> = {
      limit,
      offset,
      pdf: true,
    };
    if (encounterId) {
      params.encounter = encounterId;
    }
    if (nurseRecordStartDate) {
      params.from_date = nurseRecordStartDate;
    }
    if (nurseRecordEndDate) {
      params.to_date = nurseRecordEndDate;
    }
    const [printNurseNoteSuccess, printNurseNoteError] =
      await this.MainPHVManager.postPrintNurseNote(params);
    if (printNurseNoteError) {
      toast.error("เกิดข้อผิดพลาด");
    } else if (!printNurseNoteSuccess.success) {
      toast.error(
        printNurseNoteSuccess.error
          ? printNurseNoteSuccess.error
          : "เกิดข้อผิดพลาด"
      );
    }
  };
  handleGetPatientAssessment = async ({
    nurseRecordStartDate,
    nurseRecordEndDate,
    encounterId,
  }: any = {}) => {
    if (!encounterId) {
      return;
    }
    this.setState({ patientAssessmentLoading: true });
    const [patientAssessmentData, patientAssessmentError] =
      await this.MainPHVManager.getPatientAssessment(
        encounterId,
        nurseRecordStartDate,
        nurseRecordEndDate
      );
    for (var keys in patientAssessmentError) {
      if (patientAssessmentError[keys]) {
        toast.error(keys + ": " + patientAssessmentError[keys].detail);
      }
    }
    this.setState({
      patientAssessmentLoading: false,
      patientAssessmentData: patientAssessmentData,
    });
  };
  handleGetClinicalTerm = async (type: string) => {
    let params = {
      type,
    };
    const [clinicalTerm, clinicalTermError] =
      await this.MainPHVManager.getClinicalTerm(params);
    if (clinicalTermError) {
      toast.error(clinicalTermError);
    }
    this.setState({
      clinicalTermData: {
        ...this.state.clinicalTermData,
        [type]: clinicalTerm,
      },
    });
  };
  handleGetDischargePlanning = async ({
    nurseRecordStartDate,
    nurseRecordEndDate,
    encounterId,
  }: any = {}) => {
    if (!encounterId) {
      return;
    }
    // encounterId = 174551
    this.setState({ dischargePlanningLoading: true });
    const [dischargePlanning, dischargePlanningError] =
      await this.MainPHVManager.getDischargePlanning(
        encounterId,
        nurseRecordStartDate,
        nurseRecordEndDate
      );
    for (var keys in dischargePlanningError) {
      if (dischargePlanningError[keys]) {
        if (typeof dischargePlanningError[keys] === "object") {
          for (var subkey in dischargePlanningError[keys]) {
            toast.error(keys + ": " + dischargePlanningError[keys][subkey]);
          }
        } else {
          toast.error(keys + ": " + dischargePlanningError[keys]);
        }
      }
    }
    this.setState({ dischargePlanningLoading: false, dischargePlanning });
  };
  handleGetDischargeTeaching = async ({
    nurseRecordStartDate,
    nurseRecordEndDate,
    encounterId,
  }: any = {}) => {
    if (!encounterId) {
      return;
    }
    let params: Record<string, any> = {
      form_version: 0.1,
      form_code: "CardReassessment",
    };
    if (encounterId) {
      params.encounter = encounterId;
    }
    this.setState({ reassessmentLoading: true });
    const [reassessmentData, reassessmentError] =
      await this.MainPHVManager.getReassessment(encounterId, params);
    if (reassessmentError) {
      toast.error(reassessmentError.detail);
    }
    this.setState({
      reassessmentLoading: false,
      reassessmentData: reassessmentData,
    });
  };
  handleGetMultidisciplinary = async ({
    nurseRecordStartDate,
    nurseRecordEndDate,
    encounterId,
    currentPage,
    practitionerRole,
  }: any = {}) => {
    let limit = 10;
    let offset = this.getOffset(limit, currentPage);
    if (!encounterId) {
      return;
    }
    let params: Record<string, any> = {
      limit,
      offset,
    };
    if (encounterId) {
      params.encounter = encounterId;
    }
    if (nurseRecordStartDate) {
      params.start_date = nurseRecordStartDate;
    }
    if (nurseRecordEndDate) {
      params.end_date = nurseRecordEndDate;
    }
    if (practitionerRole) {
      params.practitioner_role = practitionerRole;
    }
    console.log("handleGetMultidisciplinary: " + params);
    this.setState({ multidisciplinaryLoading: true });
    const [multidisciplinaryData, multidisciplinaryError, numberOfPage] =
      await this.MainPHVManager.getMultidisciplinary(params);
    if (multidisciplinaryError) {
      toast.error(multidisciplinaryError);
    }
    if (numberOfPage) {
      this.setNumberOfPage("multidisciplinary", numberOfPage);
    }
    this.setState({
      multidisciplinaryLoading: false,
      multidisciplinaryData: multidisciplinaryData,
    });
  };
  handlePrintMultidisciplinary = async (params: any) => {
    let itemList = this.state.multidisciplinaryData.map((items: any) => {
      return items.multidisciplinary_record;
    });

    if (params.item_list) {
      itemList = params.item_list;
    }

    let data = {
      item_list: itemList,
      encounter: params.encounter,
      pdf: params.pdf,
    };
    if (itemList.length === 0) {
      return toast.warn("ไม่มีข้อมูล multidisciplinary");
    }
    const [printMultidisciplinarySuccess, printMultidisciplinaryError] =
      // await this.MainPHVManager.postPrintMultidisciplinary(data);
      await this.props.proxyController.printMultidisciplinary(
        data
      );
    if (printMultidisciplinaryError) {
      toast.error(printMultidisciplinaryError);
    } else if (!printMultidisciplinarySuccess.success) {
      toast.error(
        printMultidisciplinarySuccess.error
          ? printMultidisciplinarySuccess.error.message
          : "เกิดข้อผิดพลาด"
      );
    }
  };
  handleGetScannedDocument = async ({
    docStartDate,
    docEndDate,
    scanStartDate,
    scanEndDate,
    expiredStartDate,
    expiredEndDate,
    documentCategory,
    scanDivision,
    ownerDivision,
    docNo,
    scanUser,
    encounterDoctor,
    hnPatient,
    currentScannedDocPage,
    documentType,
  }: any = {}) => {
    if (!currentScannedDocPage) {
      currentScannedDocPage = this.state.currentScannedDocPage;
    }
    let limit = 100;
    let offset = this.getOffset(limit, currentScannedDocPage);
    let params: Record<string, any> = {
      limit,
      offset,
    };
    if (hnPatient) {
      params.patient__hn = hnPatient;
    }
    if (documentType) {
      params.document_type = documentType;
    }
    if (scanDivision) {
      params.scan_division = scanDivision;
    }
    if (scanUser) {
      params.scan_user = scanUser;
    }
    if (docNo) {
      params.document_no = docNo;
    }
    if (ownerDivision) {
      params.owner_division = ownerDivision;
    }
    if (encounterDoctor) {
      params.encounter__doctor = encounterDoctor;
    }
    if (docStartDate) {
      params.doc_date_from = docStartDate;
    }
    if (docEndDate) {
      params.doc_date_to = docEndDate;
    }
    if (scanStartDate) {
      params.scan_date_from = scanStartDate;
    }
    if (scanEndDate) {
      params.scan_date_to = scanEndDate;
    }
    if (expiredStartDate) {
      params.expiry_date_from = expiredStartDate;
    }
    if (expiredEndDate) {
      params.expiry_date_to = expiredEndDate;
    }
    this.setState({ scannedDocLoading: true });
    const [scannedDocData, scannedDocError, numberOfPage] =
      await this.MainPHVManager.getScannedDoc(params);
    if (scannedDocError) {
      toast.error(scannedDocError);
    }
    if (numberOfPage) {
      this.setState({ scannedDocNumberOfPage: numberOfPage });
    }
    this.setState({
      currentScannedDocPage,
      scannedDocLoading: false,
      scannedDocData: scannedDocData,
    });
  };
  handleGetVitalSign = async ({
    startDate,
    endDate,
    startTime,
    endTime,
    encounterId,
    limit,
    offset,
  }: any = {}) => {
    if (!encounterId) {
      return [null, "กรุณาระบุ encounter"];
    }
    let params: Record<string, any> = {
      history_view: true,
      sort_by_seq: true,
      invert_order: true,
    };
    if (encounterId) {
      params.encounter = encounterId;
    }
    if (startDate) {
      params.from_date = startDate;
    }
    if (endDate) {
      params.to_date = endDate;
    }
    if (startTime) {
      params.from_time = startTime;
    }
    if (endTime) {
      params.to_time = endTime;
    }
    if (limit && offset) {
      params.limit = limit;
      params.offset = offset;
    } else {
      params.limit = 20;
      params.offset = 0;
    }
    const [vitalSignData, vitalSignError] =
      await this.MainPHVManager.getVitalSign(params);
    return [vitalSignData, vitalSignError];
  };
  handleGetLastHeight = async () => {
    const [response, responseError] = await this.ptmManager.getLastHeight(
      this.state.patientPanelData.patientData.id
    );
    return [response, responseError];
  };
  handleGetScannedDocumentOptions = async () => {
    console.log("handleGetScannedDocumentOptions ");
    const [scannedDocOptions, scannedDocOptionsError] =
      await this.MainPHVManager.getScannedDocOptions();
    for (var keys in scannedDocOptionsError) {
      if (scannedDocOptionsError[keys]) {
        if (typeof scannedDocOptionsError[keys] === "object") {
          for (var subkey in scannedDocOptionsError[keys]) {
            toast.error(keys + ": " + scannedDocOptionsError[keys][subkey]);
          }
        } else {
          toast.error(keys + ": " + scannedDocOptionsError[keys]);
        }
      }
    }
    let options: Record<string, any> = {};
    let docCategory = formatDropdown(
      scannedDocOptions.docCategory,
      "name",
      "id"
    );
    // let docType = formatDropdown(scannedDocOptions.docType, "name", "id");
    let division = formatDropdown(
      scannedDocOptions.division,
      "name_code",
      "id"
    );
    options.docCategory = docCategory;
    // options.docType = docType;
    options.division = division;
    this.setState({ scannedDocOptions: options });
  };
  handleGetDocumentType = async ({ documentCategory }: any = {}) => {
    let params: Record<string, any> = {
      limit: 99999,
    };
    if (documentCategory) {
      params.category = documentCategory;
    }
    this.setState({ scannedDocLoading: true });
    const [scannedDocType, scannedDocTypeError] =
      await this.MainPHVManager.getScannedDocType(params);
    let options = formatDropdown(scannedDocType, "name", "id");
    let newScannedDocOptions = Object.assign(this.state.scannedDocOptions);
    newScannedDocOptions.docType = options;
    this.setState({
      scannedDocOptions: newScannedDocOptions,
      scannedDocLoading: false,
    });
  };
  handleGetSearchUserList = async (searchText: string) => {
    let params: Record<string, any> = {};
    if (searchText) {
      params.full_name = searchText;
    }
    this.setState({ userListLoading: true });
    const [userList, userError] = await this.MainPHVManager.getUserList(params);
    let userOptions = formatDropdown(userList, "full_name", "id");
    this.setState({ userList: userOptions, userListLoading: false });
  };
  handleGetSearchDoctorList = async (searchText: string) => {
    let params: Record<string, any> = {};
    if (searchText) {
      params.search = searchText;
    }
    this.setState({ doctorListLoading: true });
    const [doctorList, doctorError, numberOfPage] =
      await this.MainPHVManager.getDoctorList(params);
    if (doctorError) {
      toast.error(doctorError);
    }
    if (numberOfPage) {
      this.setState({ doctorListNumberOfPage: numberOfPage });
    }
    let doctorOptions = formatDropdown(doctorList, "full_name", "id");
    this.setState({
      scanDocDoctorList: doctorOptions,
      doctorListLoading: false,
    });
  };
  handleGetVitalSignType = async ({ encounterId }: any = {}) => {
    if (!encounterId) {
      return [null, "กรุณาระบุ encounter"];
    }
    let params = {
      encounter: encounterId,
    };
    const [vitalSignType, vitalSignTypeError] =
      await this.MainPHVManager.getVitalSignType(params);
    return [vitalSignType, vitalSignTypeError];
  };
  handleGetOrderSummary = async ({
    encounterId,
    encounterType,
    medicalStartDate,
    medicalEndDate,
    medicalRecordId,
    medicalNoteDoctor,
    medicalNotePerformUser,
    currentPage,
  }: any = {}) => {
    let limit = 3;
    let offset = this.getOffset(limit, currentPage);
    if (encounterType !== "IPD") {
      if (!encounterId) {
        return;
      }
      let params = {
        encounter: encounterId,
        sort_descending: true,
        lab: true,
        imaging: true,
        treatment: true,
        other: true,
      };
      this.setState({ orderSummaryLoading: true });
      const [orderSummaryData, orderSummaryError, numberOfPage] =
        await this.MainPHVManager.getOrderSummaryOPD(params);
      if (orderSummaryError) {
        toast.error(orderSummaryError);
      }
      if (numberOfPage) {
        this.setNumberOfPage("orderSummary", numberOfPage);
      }
      this.setState({ orderSummaryLoading: false, orderSummaryData });
    } else {
      if (!medicalRecordId) {
        return;
      }
      let params: Record<string, any> = {
        flag_treatment_order: true,
        flag_note_order: true,
        flag_pharmacy: true,
        flag_lab: true,
        flag_food: true,
        flag_or: true,
        sort_descending: true,
        limit_by_warded: true,
        limit,
        offset,
      };
      if (medicalStartDate) {
        params.start_date = medicalStartDate;
      }
      if (medicalEndDate) {
        params.end_date = medicalEndDate;
      }
      if (medicalNoteDoctor) {
        params.order_by = medicalNoteDoctor;
      }
      if (medicalNotePerformUser) {
        params.order_perform_by = medicalNotePerformUser;
      }
      this.setState({ orderSummaryLoading: true });
      const [orderSummaryData, orderSummaryError, numberOfPage] =
        await this.MainPHVManager.getOrderSummary(medicalRecordId, params);
      for (var keys in orderSummaryError) {
        if (orderSummaryError[keys]) {
          if (typeof orderSummaryError[keys] === "object") {
            for (var subkey in orderSummaryError[keys]) {
              toast.error(keys + ": " + orderSummaryError[keys][subkey]);
            }
          } else {
            toast.error(keys + ": " + orderSummaryError[keys]);
          }
        }
      }
      if (numberOfPage) {
        this.setNumberOfPage("orderSummary", numberOfPage);
      }
      this.setState({
        orderSummaryLoading: false,
        orderSummaryData: orderSummaryData,
      });
    }
  };
  handlePrintOrderSummary = async ({
    medicalRecordId,
    encounterType,
    encounterId,
    medicalStartDate,
    medicalEndDate,
  }: any = {}) => {
    /* console.log('handlePrintOrderSummary param',
        medicalRecordId,
        encounterType,
        encounterId,
        medicalStartDate,
        medicalEndDate) */
    let params: Record<string, any> = {};
    if (encounterType === "OPD") {
      if (encounterId) {
        params.encounter_id = encounterId;
      }
      const [printOrderSummary, printOrderSummaryError] =
        await this.MainPHVManager.getPrintOPDOrderSummary(params);
      if (printOrderSummaryError) {
        return printOrderSummaryError;
        // return toast.error("เกิดข้อผิดพลาด");
      } else {
        return printOrderSummary;
      }
    } else {
      params = {
        ...params,
        disable_pagination: true,
      };
      if (medicalRecordId) {
        params.emr = medicalRecordId;
      }
      if (medicalStartDate) {
        params.start_date = medicalStartDate;
      }
      if (medicalEndDate) {
        params.end_date = medicalEndDate;
      }
      const [printOrderSummary, printOrderSummaryError] =
        await this.MainPHVManager.getPrintIPDOrderSummary(params);
      if (printOrderSummaryError) {
        return printOrderSummaryError;
        // return toast.error("เกิดข้อผิดพลาด");
      } else {
        // Popup a waiting dialog
        return printOrderSummary;
      }
    }
  };
  handleGetSecretEncounter = async ({ patientId }: any = {}) => {
    if (!patientId) {
      return;
    }
    let params = {
      patient: patientId,
    };
    this.setState({ secretEncounterLoading: true });
    const [secretEncounterData, secretEncounterError] =
      await this.MainPHVManager.getSecretEncounter(params);
    if (secretEncounterError) {
      toast.error(secretEncounterError);
    }
    this.setState({ secretEncounterLoading: false, secretEncounterData });
  };
  handleSaveSecretEncounter = async ({
    secretEncounter,
    patientId,
  }: any = {}) => {
    if (!patientId) {
      return;
    }
    let params = {
      patient: patientId,
    };
    let data = {
      items: secretEncounter,
    };
    this.setState({ secretEncounterLoading: true });
    const [secretEncounterData, secretEncounterError] =
      await this.MainPHVManager.postSecretEncounter(data, params);
    if (secretEncounterError) {
      toast.error(secretEncounterError);
    }
    this.setState({ secretEncounterLoading: false, secretEncounterData });
    if (secretEncounterData) {
      toast.success("บันทึกสำเร็จ");
    }
  };
  handleOpenPACS = async ({ hnPatient }: any = {}) => {
    let url =
      "http://pacscnmi4/explore.asp?path=/All%20Patients/InternalPatientUID=" +
      hnPatient;
    window.open(url, "_blank");
  };
  handleGetPatientOldName = async (patientId: number) => {
    this.setState({ patientOldNameLoading: true });
    const [patientOldNameData, patientOldNameError] =
      await this.MainPHVManager.getPatientOldName(patientId);
    if (patientOldNameError) {
      toast.error(patientOldNameError);
    }
    this.setState({ patientOldNameLoading: false, patientOldNameData });
  };
  getANCRecordPDF = async ({ encounterId }: any = {}) => {
    const [print, printError] = await this.lrmManager.printANCRecordByEncounter(
      {
        encounterId,
        pdf: true,
      }
    );
    return [print, printError];
  };
  handleClearPatientData = () => {
    //
    this.setState({
      patientPanelData: {
        documentNote: this.state.patientPanelData.documentNote,
      },
      admissionNoteData: {},
      consultNoteData: [],
      dischargeSummaryData: {},
      treatmentNoteData: [],
      multidisciplinaryData: [],
      operativeNote: [],
      nurseNoteData: [],
      patientAssessmentData: {},
      clinicalTermData: {},
      dischargePlanning: {},
      reassessmentData: [],
      scannedDocData: [],
      scannedDocOptions: {},
      currentScannedDocPage: 1,
      userList: [],
      scanDocDoctorList: [],
      numberOfPage: {},
      medicalNoteUserList: [],
      orderSummaryData: {},
      secretEncounterData: [],
      patientOldNameData: [],
      // Card TEC
      isShowTECSession: false,
      selectedPatientData: {},
      selectedSessionId: 0,
    });
  };
  // Nursing Diagnosis
  getNurseDiagnosisResultGroup = async ({
    encounterId,
    startDate,
    endDate,
  }: any = {}) => {
    const [data, error] = await this.ptmManager.getNurseDiagnosisResultGroup({
      encounterId,
      startDate,
      endDate,
    });
    return [data, error];
  };
  getNurseDiagnosisPDF = async ({ documentNo, pdf }: any = {}) => {
    const [data, error] = await this.ptmManager.putNurseDiagPrintPhv({
      documentNo,
      pdf,
    });
    return [data, error];
  };
  handleshowTECSessionCard = (isShow: boolean) => {
    if (isShow) {
      this.setState({ isShowTECSession: isShow });
    } else {
      this.setState({
        isShowTECSession: isShow,
        selectedPatientData: {},
        selectedSessionId: "",
        tecType: "",
      });
    }
  };
  handleTECEncounterPD = async (encounterId: number, pD: any) => {
    console.log("got encounterId, PD");
    const [data, error, network] = await this.tecManager.getTECSession({
      encounterId,
    });
    console.log("handleShowTECSessionCardWithPD data", data);
    console.log("handleShowTECSessionCardWithPD error", error);
    if (error) {
      this.setState({ tecType: "" });
      return;
    }
    // Get Array of SessionID then pass into CardTECSession
    if (data && data.session_ids && data.session_ids.length > 0) {
      let lastSession = data.session_ids.slice(-1)[0];
      if (typeof lastSession === "number") {
        this.setState({ cardPHVLoading: true });
        const [sessionData, sessionError, sessionNetwork] =
          await TECSessionDetail.retrieve({
            pk: lastSession.toString(),
          });
        if (sessionError) {
          this.setState({ tecType: "", cardPHVLoading: false });
          return;
        } else {
          console.log("TECSessionDetail updateData result", sessionData);
          //let detailAssessment = {};
          //let detailProgression = {};
          //if (sessionData.assessment !== "") {
          //  detailAssessment = JSON.parse(result.assessment);
          //}
          //if (result.progression_note !== "") {
          //  detailProgression = JSON.parse(result.progression_note);
          //}
          this.setState({
            selectedPatientData: pD,
            selectedSessionId: lastSession.toString(),
            tecType: sessionData.tec_type_name,
            cardPHVLoading: false,
          });
        }
      }
    }
  };
  handleAppEncounterIdChange = (enId: any) => {
    this.setState({ appEncounterId: enId });
  };

  // ****************
  // CardChartSummary handleChartSummary
  // ****************

  handleGetCSReplyConsult = async () => {
    if (this.state.patientDataAPI?.id) {
      const query = this.cardChartSummaryRef?.current?.getReplyConsultParams();
      // console.log("query", query)
      const doctor = query.doctor;
      const division = query.division;
      const limit = query.limit;
      const offset = query.offset;

      let params = {
        patient: this.state.patientDataAPI?.id,
        doctor_search: doctor,
        division_search: division,
        limit: limit,
        offset: offset,
        is_chart_summary: true,
      };

      const [data, error, network] =
        await this.MainPHVManager.getDoctorConsultOrderRaw(params);

      if (data) {
        this.setState({ csReplyConsultList: data });
        return [data, error];
      } else {
        return [null, null];
      }
    }
    return [null, null];
  };

  handleGetCSMedication = async () => {
    if (this.state.patientDataAPI?.id) {
      let params = {
        patient: this.state.patientDataAPI?.id,
        // start_date: "30/03/2563",
        // end_date: "30/03/2564",
      };
      const [data, error, network] =
        await this.tpdManager.getDrugOrderItemForMedReconcile(params);

      if (data) {
        this.setState({ csMedicationList: data });
        return [data, error];
      } else {
        return [null, null];
      }
    }
    return [null, null];
  };

  handleGetCSPatientCare = async () => {
    if (this.state.patientDataAPI?.id) {
      let params = {
        patient: this.state.patientDataAPI?.id,
        all_latest_history: true,
      };
      const [data, error, network] = await this.ptmManager.getVitalSignValueRaw(
        params
      );
      if (data) {
        this.setState({ csPatientCareList: data });
        return [data, error];
      } else {
        return [null, null];
      }
    }
    return [null, null];
  };

  handleGetCSPendingOrderList = async () => {
    /*
    //http://localhost:8000/apis/APP/appointment/
    ?date__gte=2563-07-01
    &date__lte=2564-1-19
    &exclude_canceled=true
    &exclude_postponed=true
    &exclude_unconfirmed=true
    &offset=0
    &limit=100
    */
    if (this.state.patientDataAPI?.id) {
      const query = this.cardChartSummaryRef?.current?.getPendingOrderParams();
      const division = query.division;
      const doctor = query.doctor;
      const offset = query.offset;
      const limit = query.limit;

      // console.log("query", query)
      let params = {
        exclude_canceled: true,
        exclude_postponed: true,
        patient: this.state.patientDataAPI?.id,
        doctor_search: doctor,
        division_search: division,
        limit: limit,
        offset: offset,
        is_chart_summary: true,
      };
      // console.log("handleGetCSPendingOrderList params", params)

      const [appointment, appointmentError] =
        await this.appManager.getAppointmentOrderRaw(params);

      if (appointment) {
        this.setState({ csPendingOrderList: appointment });
        return [appointment, appointmentError];
      } else {
        return [null, null];
      }

      // return [null,null]
    }
  };

  handleGetCSDiagnosisList = async () => {
    //http://localhost:8000/apis/DPO/medical_record/300633/diagnosis/

    if (this.state.patientDataAPI?.id) {
      const query = this.cardChartSummaryRef?.current?.getDiagnosisParams();
      const division = query.division;
      const doctor = query.doctor;
      const offset = query.offset;
      const limit = query.limit;

      // console.log("query", query)
      let params = {
        patient: this.state.patientDataAPI?.id,
        doctor_search: doctor,
        division_search: division,
        limit: limit,
        offset: offset,
        is_chart_summary: true,
      };
      // console.log("handleGetCSDiagnosisList params", params)
      const [diagnosis, diagnosisError] =
        await this.coreManager.getPatientDiagnosisList(params);

      if (diagnosis) {
        this.setState({ csDianosisList: diagnosis });
        return [diagnosis, diagnosisError];
      } else {
        return [null, null];
      }
    }
  };

  handleGetCSProcedureDetail = async () => {
    // console.log(" handleGetCSProcedureDetail start", this.state.patientDataAPI?.id)
    if (this.state.patientDataAPI?.id) {
      const procedureQuery =
        this.cardChartSummaryRef?.current?.getProcedureParams();
      // console.log("handleGetCSProcedureDetail procedureQuery is ", procedureQuery)

      const division = procedureQuery.division;
      const doctor = procedureQuery.doctor;
      const offset = procedureQuery.offset;
      const limit = procedureQuery.limit;

      let params = {
        patient: this.state.patientDataAPI?.id,
        doctor_search: doctor,
        division_search: division,
        limit: limit,
        offset: offset,
        is_chart_summary: true,
      };
      const [data, error] = await this.ormManager.getOperatingProcedureSummary(
        params
      );
      this.setState({ csProcedureDetailList: data });
      console.log(" handleGetCSProcedureDetail data", data);
      return [data, error];
    } else {
      console.log(" handleGetCSProcedureDetail error");
      return [null, null];
    }
  };

  handleGetCSLabList = async () => {
    // console.log(" handleGetLabList start", this.state.patientDataAPI?.id)
    if (this.state.patientDataAPI?.id) {
      let params: {
        // limit: number,
        patient?: number;
        lab_division?: string;
        product_type?: string;
        // offset?: number ,
        is_chart_summary?: boolean;
      } = {};

      const labQuery = this.cardChartSummaryRef?.current?.getLabParams();
      // const limit = labQuery.limit
      // const offset = labQuery.page * labQuery.limit

      params.patient = this.state.patientDataAPI?.id;
      // params.limit = limit
      // params.offset = offset
      params.is_chart_summary = true;

      console.log("handleGetLabList labQuery is ", labQuery);
      if (labQuery.division_search) {
        params.lab_division = labQuery.division_search;
      }

      if (labQuery.isXray) {
        params.product_type = "XRAY";
      } else if (labQuery.isLab) {
        params.product_type = "LAB";
      }

      const [filterResult, filterResultError] =
        await this.labManager.getCentralLabResultList(params);
      this.setState({ csLabList: filterResult });
      // console.log(" handleGetLabList data", filterResult)
      return [filterResult, filterResultError];
    } else {
      // console.log(" handleGetLabList error")
      return [null, null];
    }
  };

  handlePatchDiagnosis = async (diagnosisId: number, data: any) => {
    const [response, error] = await this.dpoManager.patchPatientDiagnosis(
      diagnosisId,
      data
    );
    // TODO: Handle encounterError
    if (error) {
      console.error(" Error ");
    } else {
      console.log(" Return ");
    }
    return [response, error];
    // await this.handleGetCSDiagnosisList()
  };

  handleCSSearchEncounterTable = async () => {
    // OPD Type
    // this.state.isOPDEncounter

    // get Ref
    const paramsCSEncounter =
      this.cardChartSummaryRef?.current?.getCSEncounterParams();

    const division = paramsCSEncounter.division;
    const doctor = paramsCSEncounter.doctor;
    const page = paramsCSEncounter.page;
    const isOPD = paramsCSEncounter.isOPD;
    const isIPD = paramsCSEncounter.isIPD;
    const limit = paramsCSEncounter.limit;
    // console.log("MainPHV dataEncounter: ", paramsCSEncounter)

    var selectEncounterType: string | string[] = "";
    if (isOPD && isIPD) {
      selectEncounterType = ["IPD", "OPD", "ER", "SS"];
    } else if (isIPD) {
      selectEncounterType = "IPD";
    } else if (isOPD) {
      selectEncounterType = ["OPD", "ER", "SS"];
    }

    // default // from_date=2564-01-19&to_date=2564-01-19&division=2&exclude_canceled=true
    if (this.state.patientDataAPI?.id) {
      let encounterHistoryParams = {
        patient: this.state.patientDataAPI?.id,
        limit: limit,
        offset: page * limit,
        encounter_type: selectEncounterType,
        doctor_search: doctor,
        division_search: division,
        is_chart_summary: true,
      };

      const [encounterHistory, encounterError] =
        await this.MainPHVManager.getEncounterPatientOptimized(encounterHistoryParams);
      // TODO: Handle encounterError
      if (encounterHistory) {
        this.setState({ csEncounterList: encounterHistory });
      }
      return [encounterHistory, encounterError];
    }
  };

  handleGetCSMasterList = async () => {
    // Get Division List
    // Get Doctor List
    // Get LabDivision List

    // console.log("handleGetCSMasterList")

    const [divisionListData, divisionListError] =
      await this.coreManager.getDivisionForOPDChoice();
    if (divisionListError) {
      toast.error(divisionListError);
    } else {
      // console.log("handleGetCSMasterList divisionListData: ",divisionListData)
      this.setState({ allDivisionList: divisionListData });
    }

    // Get Lab

    const [division, divisionError] =
      await this.MainPHVManager.getCenterLabDivision();

    if (division) {
      console.log("handleGetCSMasterList division:", division);
      this.setState({ allLabDivisionList: division });
    }

    let params = {
      limit: 2000,
    };
    const [doctorListData, doctorListError] =
      await this.MainPHVManager.getDoctorList(params);
    if (doctorListError) {
      toast.error(doctorListError);
    } else {
      console.log("handleGetCSMasterList doctorListData:", doctorListData);
      this.setState({ allDoctorList: doctorListData });
    }
    // TODO: handle error doctorListError
  };

  handleLoadCSPatient = async (hn: string) => {
    this.setState({ csLoading: true });
    const [data, error] = await this.handleGetPatientByHN({ hn });
    if (data) {
      this.setState({ patientDataAPI: data });
      const [adrReaction, adrReactionError] =
        await this.MainPHVManager.getADRReaction(data.id);
      if (adrReaction) {
        this.setState({ csADRData: adrReaction });
      }
    }
    this.setState({ csLoading: false });
  };

  HandleCSOnSelectPatient = async (id: number, hn: string) => {
    if (hn && id) {
      this.handleLoadCSPatient(hn);
    }
  };

  handleCSonSelectPatientByEnter = async (
    id: number,
    hn: string,
    full_name: string
  ) => {
    if (hn && id) {
      this.handleLoadCSPatient(hn);
    }
  };

  handlePrintEMRSummary = (data: any) => {
    return this.props.onEvent({
      message: "PrintEMRSummaryForm",
      params: { ...data, patientPanelData: this.state.patientPanelData },
    });
  };

  handlePrintDischargeSummary = (data: any) => {
    return this.props.onEvent({
      message: "PrintDischargeSummaryForm",
      params: { ...data, patientPanelData: this.state.patientPanelData },
    });
  };

  handlePrintOperativeNote = (data: any) => {
    return this.props.onEvent({
      message: "PrintOperativeNote",
      params: { ...data, patientPanelData: this.state.patientPanelData },
    });
  };

  render() {
    console.log(this.state.preflight_data);
    return (
      <>
        <MainLayout
          django={this.state.preflight_data}
          middleStyle={{
            ...(this.state.openCardChartSummary && { padding: 0 }),
          }}
          hideHomeButton={true}
          hideSwitchUser={true}
          hideUserAccount={true}
          northStyle={{
            marginLeft: "0.75rem",
            display: this.props.showContentOnly ? "none" : "",
          }}
          north={
            <>
              {this.state.enabledChartSummary ? (
                <Button
                  size="small"
                  toggle
                  active={this.state.openCardChartSummary}
                  onClick={() => {
                    this.setState({
                      openCardChartSummary: true,
                      openCardPHV: false,
                    });
                  }}
                >
                  Chart Summary
                </Button>
              ) : null}
              <Button
                size="small"
                toggle
                active={this.state.openCardPHV}
                onClick={() => {
                  this.setState({
                    openCardChartSummary: false,
                    openCardPHV: true,
                  });
                }}
              >
                Patient History Viewer
              </Button>
            </>
          }
          middle={
            <React.Fragment>
              <ModalWaitingPrinting
                onClose={() => {
                  this.setState({ openModalWaitingPrinting: false });
                }}
                active={this.state.openModalWaitingPrinting}
                id={this.state.modalPrintId}
                progress={this.state.modalPrintProgress}
                status={this.state.modalPrintStatus}
                languageUX={this.state.userSelectedLanguage}
              />
              <ModalPrintResult
                isSuccess={this.state.printResult}
                active={this.state.openModalPrintResult}
                onButtonClick={() => {
                  this.setState({ openModalPrintResult: false });
                }}
                languageUX={this.state.userSelectedLanguage}
              />
              {this.state.openCardPHV ? (
                <CardPHV
                  toggleable={false}
                  closeable={false}
                  hnOnDidMount={true}
                  filterOnSelectPatient={
                    this.props.filterOnSelectPatient ?? true
                  }
                  showContentOnly={this.props.showContentOnly}
                  selectedRowEncounter={this.props.selectedRowEncounter}
                  patientSearchBoxStyle={{ width: "80%" }}
                  forcedSelectHN={this.state.forcedSelectHN}
                  forcedSelectPatientId={this.state.forcedSelectPatientId}
                  forcedSelectEncounterId={this.state.forcedSelectEncounterId}
                  isLoading={this.state.cardPHVLoading}
                  clearPatientData={this.handleClearPatientData}
                  numberOfPage={this.state.numberOfPage}
                  onGetClinicalTerm={this.handleGetClinicalTerm}
                  // PatientPanel
                  patientPanelData={this.state.patientPanelData}
                  patientDataAPI={this.state.patientDataAPI}
                  getEncounterHistory={this.handleEncounterFilterChange}
                  // PatientSearchBox
                  patientSearchController={this.patientSearchController}
                  getNationality={this.handleGetNationality}
                  nationalitiesList={this.state.nationalitiesList}
                  getPatientList={this.handleGetPatientList}
                  getPatientByHN={this.handleGetPatientByHN}
                  crdSearchPatientData={this.state.crdSearchPatientData}
                  onGetPatientOldName={this.handleGetPatientOldName}
                  patientOldNameData={this.state.patientOldNameData}
                  patientOldNameLoading={this.state.patientOldNameLoading}
                  patientListLoading={this.state.patientListLoading}
                  patientListNumberOfPage={this.state.patientListNumberOfPage}
                  patientCurrentPage={this.state.patientCurrentPage}
                  onPatientListPaginationChange={
                    this.handlePatientPaginationChange
                  }
                  // DoctorSearchBox
                  getSearchDoctorList={this.handleSearchDoctorList}
                  getSpecialitiesList={this.handleGetSpeciality}
                  doctorList={this.state.doctorList}
                  specialitiesList={this.state.specialitiesList}
                  doctorListLoading={this.state.doctorListLoading}
                  doctorListNumberOfPage={this.state.doctorListNumberOfPage}
                  doctorCurrentPage={this.state.doctorCurrentPage}
                  onDoctorListPageChange={this.handleDoctorPaginationPageChange}
                  // FilterDateRange
                  onFilterDate={this.handleOnFilterDate}
                  getSearchDoctor={this.handleGetSearchDoctor}
                  // EMRSummary
                  getEMRSummaryData={this.handleGetEMRSummaryData}
                  onGetAdverseReaction={this.handleGetAdverseReaction}
                  getDischargeSummaryOnlyData={
                    this.handleGetDischargeSummaryOnlyData
                  }
                  getEMRSummaryPrint={this.handleGetEMRSummaryPrint}
                  // Medication Old
                  getMedicationRecord={this.handleGetmedicationRecord}
                  onMedicaltionRecordPrint={this.handleMedicationRecordPrint}
                  // Medication New Version
                  getHomeMedDetail={this.handleGetHomeMedDetail}
                  //Medication Reconcile
                  getMedReconciliation={this.handleGetMedReconciliation}
                  getMedReconciliationDetail={
                    this.handleGetMedReconciliationDetail
                  }
                  getMedReconciliationLog={this.handleGetMedReconciliationLog}
                  // Lab
                  getLabCode={this.handleGetLabCode}
                  getLabDetail={this.handleGetLabDetail}
                  getInterfaceSummaryReportDetail={
                    this.handleGetInterfaceSummaryReportDetail
                  }
                  // ImagingExam
                  // imagingExamData={this.state.imagingExamData}
                  getImagingExamResult={this.handleGetImagingExamResult}
                  onOpenPACS={this.handleOpenPACS}
                  progressionNote={this.state.progressionNote}
                  getProgressionNote={this.getProgressionNoteData}
                  getEncounterDetail={this.handleGetEncounterDetail}
                  progressNoteLoading={this.state.progressNoteLoading}
                  dischargeSummaryData={this.state.dischargeSummaryData}
                  // callback
                  admissionNoteData={this.state.admissionNoteData}
                  admissionNoteLoading={this.state.admissionNoteLoading}
                  getAdmissionNote={this.handleGetAdmissionNote}
                  onGetDischargeSummary={this.handleGetDischargeSummary}
                  dischargeSummaryLoading={this.state.dischargeSummaryLoading}
                  consultNoteData={this.state.consultNoteData}
                  onGetConsultNote={this.handleGetConsultNote}
                  consultNoteLoading={this.state.consultNoteLoading}
                  onGetTreatmentResult={this.handleGetTreatmentResult}
                  treatmentNoteLoading={this.state.treatmentNoteLoading}
                  treatmentNoteData={this.state.treatmentNoteData}
                  onGetSensitiveNote={this.handleGetSensitiveNote}
                  sensitiveNoteData={this.state.sensitiveNoteData}
                  sensitiveNoteLoading={this.state.sensitiveNoteLoading}
                  onGetImageGallery={this.handleGetImageGallery}
                  imageGalleryData={this.state.imageGalleryData}
                  imageGalleryLoading={this.state.imageGalleryLoading}
                  operativeNote={this.state.operativeNote}
                  // Nurse Note
                  onGetNurseNote={this.handleGetNurseNote}
                  nurseNote={this.state.nurseNoteData}
                  nurseNoteLoading={this.state.nurseNoteLoading}
                  onPrintNurseNote={this.handlePrintNurseNote}
                  // Nursing Diagnosis
                  divisionType={this.props.divisionType}
                  django={this.state.preflight_data}
                  medReconcileCheck={this.props.medReconcileCheck}
                  medReconcileIndex={this.props.medReconcileIndex}
                  NursingDiagnosisSequence={this.props.NursingDiagnosisSequence}
                  // Patient Assessment
                  onGetPatientAssessment={this.handleGetPatientAssessment}
                  patientAssessmentData={this.state.patientAssessmentData}
                  patientAssessmentLoading={this.state.patientAssessmentLoading}
                  clinicalTermData={this.state.clinicalTermData}
                  // Discharge Planning
                  onGetDischargePlanning={this.handleGetDischargePlanning}
                  dischargePlanning={this.state.dischargePlanning}
                  dischargePlanningLoading={this.state.dischargePlanningLoading}
                  // Discharge Teaching
                  onGetDischargeTeaching={this.handleGetDischargeTeaching}
                  reassessmentLoading={this.state.reassessmentLoading}
                  reassessmentData={this.state.reassessmentData}
                  // Multidisciplinary
                  onGetMultidisciplinary={this.handleGetMultidisciplinary}
                  multidisciplinaryLoading={this.state.multidisciplinaryLoading}
                  multidisciplinaryData={this.state.multidisciplinaryData}
                  crdMultiDisciplinaryController={
                    this.crdMultiDisciplinaryController
                  }
                  onPrintMultidisciplinary={this.handlePrintMultidisciplinary}
                  // Scanned document
                  onGetScannedDocument={this.handleGetScannedDocument}
                  scannedDocLoading={this.state.scannedDocLoading}
                  scannedDocData={this.state.scannedDocData}
                  scannedDocNumberOfPage={this.state.scannedDocNumberOfPage}
                  currentScannedDocPage={this.state.currentScannedDocPage}
                  onScannedDocPaginationChange={this.handleGetScannedDocument}
                  onGetScannedDocumentOptions={
                    this.handleGetScannedDocumentOptions
                  }
                  scannedDocOptions={this.state.scannedDocOptions}
                  onGetSearchUserList={this.handleGetSearchUserList}
                  userList={this.state.userList}
                  userListLoading={this.state.userListLoading}
                  onGetSearchDoctorList={this.handleGetSearchDoctorList}
                  scanDocDoctorList={this.state.scanDocDoctorList}
                  onGetDocumentType={this.handleGetDocumentType}
                  // Vital Sign
                  onGetVitalSign={this.handleGetVitalSign}
                  onGetLastHeight={this.handleGetLastHeight}
                  onGetVitalSignType={this.handleGetVitalSignType}
                  medicalNoteUserListLoading={
                    this.state.medicalNoteUserListLoading
                  }
                  medicalNoteUserList={this.state.medicalNoteUserList}
                  // orderSummary
                  onGetOrderSummary={this.handleGetOrderSummary}
                  orderSummaryLoading={this.state.orderSummaryLoading}
                  orderSummaryData={this.state.orderSummaryData}
                  onPrintOrderSummary={this.handlePrintOrderSummary}
                  // Secret Encounter
                  onGetSecretEncounter={this.handleGetSecretEncounter}
                  secretEncounterLoading={this.state.secretEncounterLoading}
                  secretEncounterData={this.state.secretEncounterData}
                  onSaveSecretEncounter={this.handleSaveSecretEncounter}
                  getEncounterList={this.handleGetEncounterList}
                  userPermission={this.state.userPermission}
                  // ANC Record
                  getANCRecordPDF={this.getANCRecordPDF}
                  // Nursing Diagnosis
                  getNurseDiagnosisResultGroup={
                    this.getNurseDiagnosisResultGroup
                  }
                  getNurseDiagnosisPDF={this.getNurseDiagnosisPDF}
                  isShowTECSession={this.state.isShowTECSession}
                  showTECSessionCard={this.handleshowTECSessionCard}
                  handleTECEncounterPD={this.handleTECEncounterPD}
                  //subICD10DetailController={this.subICD10DetailController}
                  //cardLabResultController={this.cardLabResultController}
                  //cardImagingExamResultController={this.cardImagingExamResultController}
                  // Blood
                  cardBloodTransfusionHistoryController={
                    this.cardBloodTransfusionHistoryController
                  }
                  // Medical Note
                  doctorSearchBoxController={this.doctorSearchBoxController}
                  cardMedicalNoteController={this.cardMedicalNoteController}
                  cardOperativeNoteViewController={
                    this.cardOperativeNoteViewController
                  }
                  django={this.state.preflight_data}
                  // function
                  onEvent={this.props.onEvent}
                  setProp={this.props.setProp}
                  // controller
                  proxyController={this.props.proxyController}
                  subICD10DetailController={this.subICD10DetailController}
                  // CommonInterface
                  errorMessage={this.props.errorMessage}
                  successMessage={this.props.successMessage}
                  buttonLoadCheck={this.props.buttonLoadCheck}
                  division={this.props.division}
                  // options
                  masterOptions={this.props.masterOptions}
                  masterData={this.props.masterData}
                  ChoiceTriage={this.props.ChoiceTriage}
                  // seq
                  runSequence={this.props.runSequence}
                  AssessmentSequence={this.props.AssessmentSequence}
                  HistoryCentralLabSequence={
                    this.props.HistoryCentralLabSequence
                  }
                  ORPostOperationSequence={this.props.ORPostOperationSequence}
                  ORHistorySequence={this.props.ORHistorySequence}
                  ORRequestSequence={this.props.ORRequestSequence}
                  PerioperativeNursingSequence={
                    this.props.PerioperativeNursingSequence
                  }
                  PreOperationSequence={this.props.PreOperationSequence}
                  PreAssessmentSequence={this.props.PreAssessmentSequence}
                  ReAssessmentSequence={this.props.ReAssessmentSequence}
                  // SearchBox
                  searchedItemListWithKey={this.props.searchedItemListWithKey}
                  // data
                  preAssessmentOptions={this.props.preAssessmentOptions}
                  preAssessmentActionLog={this.props.preAssessmentActionLog}
                  reAssessmentOptions={this.props.reAssessmentOptions}
                  subICDController={this.props.subICDController}
                  selectedPatient={this.props.selectedPatient}
                  selectedEncounter={this.props.selectedEncounter}
                  patientDataAPI={this.state.patientDataAPI}
                  loadingStatus={this.props.loadingStatus}
                  userTokenize={this.props.userTokenize}
                  // Imaging Result
                  ImagingResultSequence={this.props.ImagingResultSequence}
                  // CommonInterface
                  modXrayDetail={this.props.modXrayDetail}
                  // ----- Dental Diagram
                  clinicalFindingId={this.props.clinicalFindingId}
                  clinicalFindingIndex={this.props.clinicalFindingIndex}
                  clinicalFindingList={this.props.clinicalFindingList || []}
                  organ={this.props.organ}
                  clinicaltags={this.props.clinicaltags}
                  filterClinicalFindingIds={
                    this.props.filterClinicalFindingIds || []
                  }
                  onPrintEMRSummary={this.handlePrintEMRSummary}
                  onPrintDischargeSummary={this.handlePrintDischargeSummary}
                  onPrintOperativeNote={this.handlePrintOperativeNote}
                  // CardPreAnesthetic
                  selectedDoctor={this.props.selectedDoctor}
                  PreAnestheticSequence={this.props.PreAnestheticSequence}
                  selectedAnesthesiologist={this.props.selectedAnesthesiologist}
                  AnestheticHistorySequence={this.props.AnestheticHistorySequence}
                  // CardAnesRecord
                  AnesRecordSequence={this.props.AnesRecordSequence}
                  languageUX={this.props.languageUX}
                  // CardPACURecord
                  PACURecordSequence={this.props.PACURecordSequence}
                  // CardPeriDataSheet
                  PeriDataSheetSequence={this.props.PeriDataSheetSequence}
                  // CardAnestheticComplication
                  AnesComplicationSequence={this.props.AnesComplicationSequence}
                  // CardAnesBill
                  cardANSBillController={this.props.cardANSBillController}
                  billDrugOrder={this.props.billDrugOrder}
                  selectedEmr={this.props.selectedEmr}
                  selectedProgressCycle={this.props.selectedProgressCycle}
                  AnesBillSequence={this.props.AnesBillSequence}
                />
              ) : null}
              {this.state.openCardChartSummary ? (
                <CardChartSummary
                  // PatientSearchBox
                  patientSearchController={this.patientSearchController}
                  forcedSelectHN={this.state.forcedSelectHN}
                  nationalitiesList={this.state.nationalitiesList}
                  crdSearchPatientData={this.state.crdSearchPatientData}
                  patientCurrentPage={this.state.patientCurrentPage}
                  patientOldNameData={this.state.patientOldNameData}
                  patientOldNameLoading={this.state.patientOldNameLoading}
                  patientListLoading={this.state.patientListLoading}
                  onSelectPatient={this.HandleCSOnSelectPatient}
                  // Control
                  csLoading={this.state.csLoading}
                  patientDataAPI={this.state.patientDataAPI}
                  ref={this.cardChartSummaryRef}
                  isNurse={this.state.isNurse}
                  allDoctorList={this.state.allDoctorList}
                  allDivisionList={this.state.allDivisionList}
                  allLabDivisionList={this.state.allLabDivisionList}
                  // Handle
                  handleGetCSLabList={this.handleGetCSLabList}
                  handleGetChartSummaryAllList={this.handleGetCSMasterList}
                  handleCSSearchEncounterTable={
                    this.handleCSSearchEncounterTable
                  }
                  handleGetCSDiagnosisList={this.handleGetCSDiagnosisList}
                  handleGetCSPendingOrderList={this.handleGetCSPendingOrderList}
                  handleGetCSMedication={this.handleGetCSMedication}
                  handleGetCSPatientCare={this.handleGetCSPatientCare}
                  handleGetCSReplyConsult={this.handleGetCSReplyConsult}
                  handleGetCSProcedureDetail={this.handleGetCSProcedureDetail}
                  handlePatchDiagnosis={this.handlePatchDiagnosis}
                  handleCSonSelectPatientByEnter={
                    this.handleCSonSelectPatientByEnter
                  }
                  // Data

                  csEncounterList={this.state.csEncounterList}
                  csLabList={this.state.csLabList}
                  csDiagnosisList={this.state.csDianosisList}
                  csPendingOrderList={this.state.csPendingOrderList}
                  csMedicationList={this.state.csMedicationList}
                  csPatientCareList={this.state.csPatientCareList}
                  csReplyConsultList={this.state.csReplyConsultList}
                  csProcedureDetailList={this.state.csProcedureDetailList}
                  csADRData={this.state.csADRData}
                  patientSearchBoxStyle={{ width: "80%" }}
                  languageUX={this.state.userSelectedLanguage}
                />
              ) : null}

              {this.state.isShowTECSession ? (
                <>
                  {this.state.tecType === "PHYSICAL" ||
                  this.state.tecType === "OCCUPATIONAL" ? (
                    <div style={{ marginBottom: "10px" }}>
                      <CardTECSession
                        patientData={this.state.selectedPatientData}
                        appEncounterId={this.state.appEncounterId}
                        onAppEncounterIdChange={this.handleAppEncounterIdChange}
                        subICD10DetailController={this.subICD10DetailController}
                        readonlySession={true}
                        cardLabResultController={this.cardLabResultController}
                        cardImagingExamResultController={
                          this.cardImagingExamResultController
                        }
                        sessionId={this.state.selectedSessionId}
                        onEvent={() => {}}
                        languageUX={this.state.userSelectedLanguage}
                      />
                    </div>
                  ) : null}
                  {this.state.tecType !== "OTHER_REHAB" &&
                  !this.state.tecType.includes("COM_") ? (
                    <CardTECSession
                      onAppEncounterIdChange={this.handleAppEncounterIdChange}
                      patientData={this.state.selectedPatientData}
                      hideLab
                      appEncounterId={this.state.appEncounterId}
                      isOpenAssessment
                      subICD10DetailController={this.subICD10DetailController}
                      readonlySession={true}
                      cardLabResultController={this.cardLabResultController}
                      cardImagingExamResultController={
                        this.cardImagingExamResultController
                      }
                      sessionId={this.state.selectedSessionId}
                      onEvent={() => {}}
                      languageUX={this.state.userSelectedLanguage}
                    />
                  ) : null}
                </>
              ) : null}
            </React.Fragment>
          }
          languageUX={this.props.languageUX}
        />
      </>
    );
  }
}

export default withInterceptor(MainPHV);
